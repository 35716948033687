import React from 'react'
import { Grid, Paper, makeStyles, TextField, InputAdornment } from '@material-ui/core'
import { VideoWarningLottieMotion } from '../ui/LottieMotion'
import { ModalTitle } from '../ui/Title'
import PersonIcon from '@material-ui/icons/Person';
import Backdrop from '@mui/material/Backdrop';
import { CompleteFormManually, CreateTicketOdoo } from '../ui/Buttons'

const useStyles = makeStyles(theme => ({
    modal: {
        backgroundColor: "white !important",   
        width: "50%",
        margin: "100px auto",
        borderRadius: 30,
        [theme.breakpoints.down("xs")]: {
          width: "95%",
        }
    },
    modal__gridItem: {
      margin: "12px"
    }
}))

export const VideoAskPendingModal = ({ 
    userData, 
    handleChangeState, 
    handleContinueCompleteForm,
    handleCreateTicketOdoo,
    validatePhoneNumber,
    validateEmail
 }) => {
    const classes = useStyles();
    return (
    <Backdrop  
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>
        <Grid item xs={12}>
            <Paper elevation={10} className={classes.modal}>
                <Grid align='center'>
                {VideoWarningLottieMotion()}
                <ModalTitle/>
                <Grid item xs={12} sm={6} md={6} className={classes.modal__gridItem}>
                    <TextField
                        id="email"
                        name="email"
                        required
                        label="Correo electrónico"
                        variant="outlined"
                        size="small"
                        fullWidth
                        placeholder="Ej. fmorales@gilbertybolona.com"
                        helperText = {validateEmail(userData.email)?null:"Correo electrónico invalido"}
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <PersonIcon />
                            </InputAdornment>
                        )
                        }}
                        FormHelperTextProps={{ style: {'color': '#7c0837', 'fontSize': '.7em'}}}
                        value={userData.email !== '' ?userData.email : ''}
                        onChange={handleChangeState}
                    />
                    </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.modal__gridItem}>
                    <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Teléfono, solo Ecuador"
                    required
                    variant="outlined"
                    size="small"
                    fullWidth
                    autoComplete='+593'
                    placeholder="Ej. 0979822975"
                    helperText = {validatePhoneNumber(userData.phoneNumber) ?null: "Teléfono invalido"}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon />
                        </InputAdornment>
                        )
                    }}
                    value={userData.phoneNumber !== '' ?userData.phoneNumber : ''}
                    onChange={handleChangeState}
                    FormHelperTextProps={{ style: {'color': '#7c0837', 'fontSize': '.7em'}}}
                    />
                </Grid>
                    <Grid item display="flex">
                    <CompleteFormManually 
                        handleContinueCompleteForm ={handleContinueCompleteForm}
                    />
                    <CreateTicketOdoo
                        handleCreateTicketOdoo = {handleCreateTicketOdoo}/>
                    <br/>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    </Backdrop>
    )
}

export const CreatingOdooResponse = () => {
    const classes = useStyles();
    return (
    <Backdrop  
        sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}>
        <Grid item xs={12}>
            <Paper elevation={10} className={classes.modal}>
                <Grid align='center'>
                {VideoWarningLottieMotion()}
                <ModalTitle/>
                </Grid>
            </Paper>
        </Grid>
    </Backdrop>
    )
 }
