import {
  ComboBoxDeductibles,
  ComboBoxInsurers,
  ComboBoxProductos,
} from "../ui/Autocomplete";

import { makeStyles, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  comparativeGroup: {
    margin: "auto",
    justifyContent: "center",
    [theme.breakpoints.down(700)]: {
      width: "100px",
    },
    // [theme.breakpoints.down("md")]: {
    //     width: '100px',
    //   }
  },
}));

export const AutocompleteGroups = ({
  indexes,
  selectedPlanAction,
  insurerNames,
  planNames,
  PersonalQuote,
  selectedDeductibleAction,
  namePlans,
  nameInsurers,
  deductible,
}) => {
  const classes = useStyles();
  const [selectedInsurer, setSelectedInsurer] = useState("all");
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectPlan, setSelectPlan] = useState(null);
  const [completeDetailProduct, setCompleteDetailProduct] = useState([]);
  const [selectedDeducibles, setSelectedDeductibles] = useState([]);
  const [isDissabled, setIsDissabled] = useState(true);
  const [selectDeductibles, setSelectDeductibles] = useState(null);

  function handleInputInsurerOption(value) {
    setSelectedInsurer(value);
  }

  function handleChangePlanByInsurer() {
    const plans = [];
    const completeDetail = [];
    if (selectedInsurer === "all" || selectedInsurer === null) {
      console.log("selectedInsurer > ", selectedInsurer);
      planNames.map((v, k) => {
        plans.push(v.plan);
        completeDetail.push(v);
      });
    } else {
      planNames.map((v, k) => {
        if (v.insurer === selectedInsurer) {
          plans.push(v.plan);
          completeDetail.push(v);
        }
      });
    }
    return { plans, completeDetail };
  }

  function handleChangeDeductibleByPlan(ind) {
    const data = completeDetailProduct[ind];
    console.log("COMPLETE DETAIL ", completeDetailProduct);
    console.log("DATA DEDUCTIBLE => ", data); //deducibles del plan seleccionado
    if (data !== undefined) {
      const deductiblesDetail = PersonalQuote["result"][data.icode][data.code];
      const deductibles = Object.keys(deductiblesDetail);
      setIsDissabled(false);
      setSelectedDeductibles(deductibles);
    } else {
      setSelectedDeductibles([]);
      setIsDissabled(true)
    }
  }

  function handleInputSelectDeductibles(value) {
    console.log('VALUE AUTOMCOMPLETE => ', value)
    setSelectDeductibles(value);
  }

  function handleInputSelectPlan(value) {
    setSelectPlan(value);
  }

  function handleInputPlanOption(value) {
    if (!value) {
      setSelectedDeductibles([]);
    }
    const ind = selectedPlans.indexOf(value);
    handleChangeDeductibleByPlan(ind);
    selectedPlanAction(completeDetailProduct[ind], value, ind, indexes);
  }

  function handleInputDeductibleOption(value) {
    selectedDeductibleAction(value, indexes);
  }

  useEffect(() => {
    const detail = handleChangePlanByInsurer();
    setSelectedPlans(detail.plans);
    setCompleteDetailProduct(detail.completeDetail);
    setTimeout(() => {
      if (deductible!=""){
        const ind = selectedPlans.indexOf(namePlans);
        const data = completeDetailProduct[ind]
        const deductiblesDetail = PersonalQuote["result"][data.icode][data.code];
        const deductibles = Object.keys(deductiblesDetail);
        deductibles.pop()
        setIsDissabled(false);
        setSelectedDeductibles(deductibles);
      }
    }, 2000)


  }, [selectedInsurer]);

  return (
    <div key={indexes} className={classes.comparativeGroup}>
      <ComboBoxInsurers
        selectedInsurerAction={handleInputInsurerOption}
        insurerNames={insurerNames}
        nameInsurer={nameInsurers}
      />
      <ComboBoxProductos
        selectedPlanAction={handleInputPlanOption}
        planNames={selectedPlans}
        namePlans={namePlans}
        handleInputSelectPlan={handleInputSelectPlan}
      />
      <ComboBoxDeductibles
        selectedDeductiblesAction={handleInputDeductibleOption}
        deductibleNames={selectedDeducibles}
        isDissabled={isDissabled}
        handleInputSelectDeductibles={handleInputSelectDeductibles}
        nameDeductible={deductible}
      />
    </div>
  );
};
