import { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { dataProvider } from './../../context/data/dataProvider'
import { PaymentSliders } from './Sliders'
import { ButtonPurchasePlan } from './Buttons';
import { PeriodButtons } from '../containers/ButtonGroups'
import { CardDetailTitulares } from '../../utils/CardDetail'
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import Tooltip from '@mui/material/Tooltip';
import { useRef } from 'react';

const useStyles = makeStyles(theme => ({
  businessCard: {
    color: '#a03044',
    height: '190px',
    width: '70%',
    display: 'flex',
    boxShadow: '1px 2px 11px -4px #5f0c1b',
    transition: '0.3s ease',
    alignItems: 'center',
    borderRadius: '8px',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    backgroundColor: 'transparent',
    margin: 'auto',
    padding: '0 10px',
    textAlign: 'center',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#852e51',
      color: 'white'
    }
  },
  businessCard__icon: {
    '& svg': {
      fontSize: '2rem'
    }
  },
  businessCard__title: {
    textTransform: 'uppercase',
    fontSize: 15
  },
  paymentCard: {
    backgroundColor: ({ addDisabled }) => addDisabled ? '#a11a47' : null, //'#a03044'
    color: ({ addDisabled }) => addDisabled ? '#fff' : null, //'#fff'
    transform: ({ title, name }) => title === name ? 'scale(1.1)' : null,
    transition: '0.3s ease',
    border: '1px solid #ddd',
    borderRadius: '20px',
    height: '400px',
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '5px 5px 15px 5px rgba(0,0,0,0.13)',

    '& .MuiIconButton-root': {
      color: ({ addDisabled }) => addDisabled ? 'white' : null, //'white'
    },

    [theme.breakpoints.down(700)]: {
      height: 'inherit',
    }
  },
  paymentCard__title: {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
	marginTop: "auto"

  },
  paymentCard__title_subtitle: {
    fontSize: '0.7rem',
	marginTop: "auto"
  },
  paymentCard__price: {
    fontSize:  ({ addDisabled }) => addDisabled ? '2rem' : '1.3rem', //'2rem'
    fontFamily: 'Roboto',
    marginBottom: '10px',
	marginTop: "auto"
  },
  paymentCard__description: {
    width: '100%',
    textAlign: 'center',
    maxHeight: '70%',
	marginTop: "auto",
	overflow: "scroll",
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      fontSize: '0.9rem',

      '&:last-child': {
        marginBottom: 0,
      }
    },
  },
  paymentCard__button: {
    width: '100%',
    textAlign: 'center',
	marginTop: "auto"
  },
  paymentCard__content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
    marginBottom: '10px',
	textAlign: "initial",
	marginTop: "auto"
  },
  paymentCard__content_big: {
    padding: '0 20px',
    marginBottom: '10px',
    textAlign: 'left',
	marginTop: "auto"
  },
  cardWithIcon: {
		display: "block",
		width: "100%",
		height: "100%",
		position: "relative",
		overflow: "hidden",
		borderRadius: "20px",
		boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",

		"&:hover #titleCard": {
			transform: "translateY(100%)",
		},
		"&:hover #containerContent": {
			transform: "none",
		},
	},
	cardWithIcon__containerImg: {
		height: "100%",
		width: "100%",

		"& img": {
			height: "100%",
			width: "100%",
			objectFit: "cover",
		},
	},
	cardWithIcon__containerTitle: {
		bottom: "0",
		position: "absolute",
		backgroundColor: "#9f0844",
		color: "white",
		fontSize: "1.5rem",
		padding: "10px",
		marginLeft: "auto",
		marginRight: "auto",
		left: "50px",
		right: "50px",
		textAlign: "center",
		transition: "transform 0.2s ease-out",

		"& svg": {
			marginRight: 7,
		},
		"& span": {
			fontWeight: 600,

			[theme.breakpoints.down(600)]: {
				fontSize: "1rem",
			},
		},
	},
	cardWithIcon__containerContent: {
		position: "absolute",
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		transition: "all .2s ease-in",
		transform: "translateY(-100%)",
		background: "rgba(45, 53, 89, 0.85)",
		width: "100%",
		height: "100%",
	},
	cardWithIcon__title: {
		color: "white",

		"& svg": {
			fontSize: "2rem",
			marginRight: 7,
		},
		"& span": {
			fontSize: "1.6rem",
			fontWeight: 600,
		},
	},
	cardWithIcon__description: {
		color: "white",
		fontWeight: 400,
		margin: "15px 0",
	},
	cardWithIcon__containerButton: {
		color: "white",
		display: "flex",
		alignItems: "center",
		width: "122px",
		justifyContent: "space-between",
		backgroundColor: "#9f0844",
		height: "37px",
		borderRadius: 5,
		transition: "all .3s ease-in",

		"& span:first-child": {
			width: "100%",
			padding: "0 6px",
		},
		"& span:last-child": {
			backgroundColor: "#21294e",
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			padding: "0 6px",
		},

		"&:hover": {
			cursor: "pointer",
			textDecoration: "none",
			backgroundColor: "#1f1f1f",
		},
		"&:hover span:last-child": {
			backgroundColor: "#1f1f1f !important",
		},
	},
	pregnant__icon: {
		width: "100%",
		display: "block",
		margin: 0,
		"& svg": {
			position: "relative",
			top: 0,
			marginLeft: 10,
			color: ({ checkMaternity, addDisabled }) => !addDisabled? checkMaternity? "#7c0837": "rgb(180 182 187 / 85%)" : checkMaternity? "white": "rgb(180 182 187 / 85%)",
			borderRadius: 20,
			borderStyle: "double",
			justifySelf: "flex-end"
			},
	}
}))

export const BusinessCard = ({ icon: Icon, text}) => {
  const classes = useStyles();

  return (
    <div className={classes.businessCard}>
      <div className={classes.businessCard__icon}>
        <Icon />
      </div>
      <div className={classes.businessCard__title}>
        {text}
      </div>
    </div>
  )
}

export const PaymentCard = ({ 
		key, 
		type, 
		title, 
		subTitle, 
		prod_name,
		insurer_name,
		deductibles, 
		preferencialAmount, 
		handleChangePeriod,  
		handleClick, 
		PersonalQuote, 
		period, 
		maternity,
		handleChangeQuote,
		planes,
		somePlanWasDeleted,
		handleDeleteSelectedPlan
	}) => {

	const { data: { plan: { name } } } = useContext(dataProvider)
	

	//const state = useRef(somePlanWasDeleted)

	const build_states = () => {
		var result = []
		for (let x in period){
			if (x==0){
				result.push(preferencialAmount)
			}
			else result.push([])
		}
		return result
	}

	const [totales, setTotales] = useState(build_states());

	const [periodIndex, setPeriodIndex] = useState(0)

	const [totalIndex, setTotalIndex] = useState(0);

	const [addDisabled, setAddDisabled] = useState(false);
	const[idxSlider, setIdxSlider]=useState(0)

	const scale = () => {
		const previousMarkIndex = Math.floor(totalIndex / 25); 
		const previousMark = deductibles['national'][previousMarkIndex]; 	
		return previousMark.realValue
	};

	const handleTotalsCards = (indexSlider, indexPeriod) => {
		var quoteValues = Object.values(PersonalQuote['result'][subTitle][title])
		var valores = []
		for (let cuot in quoteValues){
			var header = Object.values(quoteValues[cuot])
			valores.push(header)
		}
		totales[indexPeriod]= valores[indexSlider]
		setTotales(totales)
	}
	
	const handleChangeTotalSlider = (event, newValue) => {		
		handleTotalsCards(Math.floor(newValue / 25), periodIndex)
		setTotalIndex(newValue)
		setIdxSlider(Math.floor(newValue / 25))
	};

	const handleChangeTotalButtons = (quote, indexPeriod) => {
		handleChangeQuote(quote)
		handleTotalsCards(Math.floor(totalIndex / 25), indexPeriod)
		setPeriodIndex(indexPeriod)		
	}

	const handleQuotingAgain = (indexPeriod) => {
		if (totales[indexPeriod].length>0){
			setPeriodIndex(indexPeriod)
			return false
		}
		return true
	}

	const handleMaternityIcon = () => {
		var ind = Math.floor(totalIndex / 25)
		const previousMark = deductibles['national'][ind]; 
		if (previousMark.realValue<=maternity) return true
		return false
	}

	const handleChangePurchasePlan = () => {
		if(planes.length<5){
			let isIncluded = planes.some(e => (e.name == prod_name && e.insurer == insurer_name))
			if (!isIncluded){
				console.log(" deductibles select=> ", deductibles)				
				const deducibleComplete = deductibles.national[idxSlider].realValue+"-"+deductibles.international[idxSlider].realValue
				console.log(" deductibles select=> ",deducibleComplete)
				handleClick(prod_name, insurer_name, totales[periodIndex][0], deducibleComplete) // Aqui hay que agregarle el deducible.
				setAddDisabled(!addDisabled)
			}
		}
	}

	const handleChangeDeleteSelectedPlan = () => {
		let value = totales[periodIndex][0]
		setAddDisabled(false)
		handleDeleteSelectedPlan(planes, {prod_name, insurer_name})
	}

	//no move this variable!
	const checkMaternity = handleMaternityIcon()
	const classes = useStyles({ type, title, name, checkMaternity, addDisabled });

  return (
    <div className={classes.paymentCard} id={key}>
		{
		<div className={classes.pregnant__icon}>
			<Tooltip title={handleMaternityIcon()? "Incluye maternidad": "No incluye Maternidad"}>
				<PregnantWomanIcon position="end"/>
			</Tooltip>
		</div>
		}
      <PeriodButtons
	  	handleChangePeriod= {handleChangePeriod}
        period={period}
		prod={title}
		insurer={subTitle}
		PersonalQuote={PersonalQuote}
		handleChangeTotalButtons={handleChangeTotalButtons}
		handleQuotingAgain={handleQuotingAgain}
		addDisabled={addDisabled}
		/>

      <div className={classes.paymentCard__title}>
        <span>{prod_name}</span>
        <span className={classes.paymentCard__title_subtitle}>{insurer_name}</span>
      </div>
      <div className={classes.paymentCard__price}>
        $ {totales[periodIndex][0]}
      </div>
		<PaymentSliders
			value = {totalIndex}
			handleChange = {handleChangeTotalSlider}
			scale = {()=>(scale(totalIndex))}
			addDisabled={addDisabled}
			deductibles={deductibles}
		/>
      <div className={classes.paymentCard__description}>
        {
          totales[periodIndex].map((item, index) => (
            <div key={index} className={classes.paymentCard__content}>
                  <>
                    <p>
                      {CardDetailTitulares(period[periodIndex][0],subTitle)[index]}
                    </p>
                    <p style={{ fontFamily: 'Ubuntu' }}><strong>{item}</strong></p>
                  </>
            </div>
          ))
        }
      </div>
      <div className={classes.paymentCard__button}>
        <ButtonPurchasePlan
			disabled={addDisabled}
          	handleClick={() => addDisabled? handleChangeDeleteSelectedPlan(): handleChangePurchasePlan()}
        />

      </div>
    </div>
  )
}

export const CardWithIcon = ({ img, icon: Icon, title, text, url }) => {
	const classes = useStyles();

	return (
		<Box className={classes.cardWithIcon}>
			<div className={classes.cardWithIcon__containerImg}>
				<img src={img} alt="icon" loading="lazy" />
			</div>

			<Box
				className={classes.cardWithIcon__containerTitle}
				display="flex"
				alignItems="center"
				justifyContent="center"
				id="titleCard"
			>
				<Icon /> <span>{title}</span>
			</Box>
			<Box
				className={classes.cardWithIcon__containerContent}
				id="containerContent"
			>
				<Box paddingY={3} paddingX={5} display="flex" flexDirection="column">
					<Box
						className={classes.cardWithIcon__title}
						display="flex"
						alignItems="center"
					>
						<Icon /> <span>{title}</span>
					</Box>
					<Typography
						className={classes.cardWithIcon__description}
						variant="body1"
					>
						{text}
					</Typography>
					{/* <Link
						className={classes.cardWithIcon__containerButton}
						href={url}
						target="blank"
					>
						<span>Ver más</span>
						<span>
							<ArrowForwardIosIcon />
						</span>
					</Link> */}
				</Box>
			</Box>
		</Box>
	);
};