const { clientAxios } = require('../config/axios')

const dic_translate_children = (children, olderChildren) => {
    var result = {}
    for (let x = 0; x < children; x++) {
        result['children-' + x] = {
            "age": 10 // It doesnt matter the age when your children is less than 24, we setted 10 just for completing the quoting.
        }
    }
    if (olderChildren != undefined && olderChildren.length > 0) {
        var child = (children != '' && children != null) ? children : 0
        for (let x = child; x < olderChildren.length + parseInt(child); x++) {
            result['children-' + x] = {
                "age": parseInt(olderChildren[x - child])
            }
        }
    }
    return result
}


const dic_translate = (data, feature) => {
    var body = {}
    body["source"] = "quoter_gyb_online"
    body['insurers'] = feature.insurer
    if (data.holderAge != '' || data.fullName != '') {
        body['holder'] = {
            "age": parseInt(data.holderAge)
        }
    }
    if (data.spouseAge != '' && data.spouseAge != null) {
        body['spouse'] = {
            "age": parseInt(data.spouseAge)
        }
    }
    if ((data.children != '' && data.children != null)) {
        body['children'] = dic_translate_children(data.children, data.olderChildren)
    }
    else if (data.olderChildren != undefined && data.olderChildren.length > 0) {
        body['children'] = dic_translate_children(data.children, data.olderChildren)
    }
    body['feature'] = {
        "product": feature.product,
        "guayas": data['region'] == "Guayas" ? true : false,
        "period": feature.period
    }
    return body
}

export const awsQuoter = async (data, feature = { period: "anual", product: "all", insurer: "all" }) => {
    var body1 = dic_translate(data, feature)
    try {
        const response = await clientAxios.post(`https://${process.env.REACT_APP_API_BASE_URL}/chatbot/v1/quotes/health`, JSON.stringify(body1))
        return JSON.parse(JSON.parse(response['data']['Payload'])['body'])
    }
    catch (error) {
        return {
            error
        }
    }
}

export const awsCustomerInfo = async (contact_id) => {
    try {
        const response = await clientAxios.get(`https://${process.env.REACT_APP_API_BASE_URL}/chatbot/v1/quotes/fieldValidation/` + contact_id)
        console.log('RESPONSE DATA ', response['data'])
        return response['data']
    } catch (error) {
        return {
            error
        }
    }
}

export const awsUpdateCustomerInfo = async (contact_id, body) => {
    try {
        const response = await clientAxios.post(`https://${process.env.REACT_APP_API_BASE_URL}/chatbot/v1/quotes/customerData/` + contact_id, JSON.stringify(body))
        return response
    } catch (error) {
        return {
            error
        }
    }
}

//benefits
export const awsBenefits = async (body) => {
    try {
        const response = await clientAxios.post(`https://${process.env.REACT_APP_API_BASE_URL}/health/v1/quotes/benefits` , JSON.stringify(body))
        return response
    } catch (error) {
        return {
            error
        }
    }
}
