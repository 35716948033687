export const provinces = [
    { label: 'Azuay' },
    { label: 'Bolivar' },
    { label: 'Cañar' },
    { label: 'Carchi' },
    { label: 'Chimborazo' },
    { label: "Cotopaxi" },
    { label: 'El Oro' },
    { label: 'Esmeraldas'},
    { label: 'Galápagos' },
    { label: 'Guayas' },
    { label: 'Imbabura'},
    { label: 'Loja'},
    { label: 'Los Rios' },
    { label: 'Manabí' },
    { label: 'Morona Santiago'},
    { label: "Napo" },
    { label: 'Orellana' },
    { label: 'Pastaza' },
    { label: 'Pichincha' },
    { label: 'Santa Elena'},
    { label: 'Santo Domingo de los Tsáchilas' },
    { label: 'Sucumbíos' },
    { label: 'Tungurahua' },
    { label: "Zamora Chinchipe" }
  ];