import { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

import { appProdiver } from './../../context/appProvider';

import Quote from './../containers/Quote';
import UserInfo from './../containers/UserInfo';
import { Footer } from './Footer';
import { InfoContainer } from './InfoContainer'
import { OpenUserInfoButton } from '../ui/Buttons'

const useStyles = makeStyles(theme => ({
  app: {
   // maxHeight: '730px',
    height: '100vh',
    display: 'flex',
    position: 'relative',
    overflow: 'scroll'
  },
  app__left: {
    width: ({ hidden }) => hidden ? '70%' :'100%',
    height: '100%',
    overflow: 'scroll',
    [theme.breakpoints.down(700)]: {
      width: '100% !important',
    },
    [theme.breakpoints.down("md")]: {
      width: '100% !important',
    }
  },
  app__right: {
    background: '#a11a47',
    width: '30%',
    height: '100%',
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
    [theme.breakpoints.down("md")]: {
      display: 'none',
    },
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp, 
      duration: theme.transitions.duration.leavingScreen
      })
  },
  hidden_app_right: {
    display: 'none'
  }
}))

const Content = () => {

  const { start, hidden, handleHiddenUserInfo, activeStep, handleNext } = useContext(appProdiver);

  const classes = useStyles({ hidden });

  return (
    <div>

    <div className={classes.app}>
      <div className={classes.app__left}>
        <Quote />
      </div>
      {
        start && hidden && (
          <div className={classes.app__right}>
            <UserInfo 
              activeStep={activeStep}
              handleNext={handleNext}
              handleCloseUserInfo = {()=>{handleHiddenUserInfo(false)}}
            />
          </div>
        )
      }
      {
        start && !hidden && (
        <OpenUserInfoButton
          text = {'Ver \n Resumen'}
          handleClick = {() => {handleHiddenUserInfo(true)}}
        />
        )
      }
    </div>

    <InfoContainer/>
    
    <Footer/>
    </div>
  )
}

export default Content
