import { makeStyles, Grid, Typography, Box } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles(theme => ({
  quote__title: {
    margin: ({ activeStep }) => activeStep === 2 ? '10px 0 20px' : '30px 0 40px',
    textAlign: 'center',

    '& h5': {
      marginBottom: 5,
      fontSize: '1.2rem',
      fontWeight: 400
    },
    '& p': {
      fontFamily: 'inherit',
      fontWeight: 200
    },

    [theme.breakpoints.down(700)]: {
      margin: '10px 0 10px !important'
    }
  },
  titleMain: {
		fontWeight: 600,
		color: ({ color }) => (color ? "#9f0844" : "white"),

		[theme.breakpoints.down(600)]: {
			fontSize: "1.5rem",
		},
	},
  modal_title: {
    fontSize: "1.2em",
    fontFamily: "inherit",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",     
    }
  },
  modal_subtitle: {
    fontSize: "1.em",
    fontFamily: "inherit",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",     
    }
  }
}));

export const Title = ({ activeStep }) => {

  const classes = useStyles({ activeStep });

  const showTitle = () => {
    let title = '';

    switch (activeStep) {
      case -1:
        title = 'Cotiza tu plan de salud ideal'
      break;

      case 0:
        title = 'Primero ingresa tus medios de contacto'
      break;

      case 1:
        title = 'Un poco más sobre ti'
      break;

      case 2:
        title = 'Te presentamos las mejores alternativas para proteger tu salud y la de tus seres queridos'
      break;
    
      default: 
        title = 'Cotiza tu plan de salud ideal'
      break;
    }

    return title;
  }

  const showSubTitle = () => {
    let subTitle = '';

    switch (activeStep) {
      case -1:
        subTitle = 'En 3 pasos sencillos vamos a calcular los mejores beneficios para ti y tu familia'
      break;

      case 1:
        subTitle = 'Tienes dependientes? Selecciona el tipo e ingresa la información requerida'
      break;
    //   default:
    //     subTitle = <>Descarga el documento <a href="#!"><GetAppIcon /></a></>
    //  break;
    }

    return subTitle
  }

  return (
    <Grid container className={classes.quote__title}>
      <Grid item xs={12}>
        <Typography variant="h5" color="initial">{showTitle()}</Typography>
        {
          activeStep !== 0 && showSubTitle() !== '' && (
            <Typography variant="body1" color="initial">{showSubTitle()}</Typography>
          )
        }
      </Grid>
    </Grid>
  )
}

export const TitleMain = ({ variant = "h4", color, text, grid }) => {
	const classes = useStyles({ color });

	if (grid)
		return (
			<Grid container>
				<Grid item xs={12}>
					<Box paddingY={2} className="mainTitle">
						<Typography
							className={classes.titleMain}
							variant={variant}
							align="center"
						>
							{text}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		);

	return (
		<Box paddingY={2} className="mainTitle">
			<Typography
				className={classes.titleMain}
				variant={variant}
				align="center"
			>
				{text}
			</Typography>
		</Box>
	);
};

export const ModalTitle = () => {
	const classes = useStyles();
  return (
		<Box paddingY={2} className="mainTitle">
			<Typography
				className={classes.modal_title}
				align="center"
			>
				<b>Oops! Algo salió mal...</b>
			</Typography>
      <Typography
        className={classes.modal_subtitle}>
          <br/>Algo inesperado ha sucedido
          <br/>Por favor déjanos tu contacto y un asesor se pondrá en contacto de inmediato. 
          <br/>Si deseas puedes llenar los campos faltantes y obtener tu cotización
      </Typography>
		</Box>
	);
}