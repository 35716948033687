import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import PeopleIcon from '@material-ui/icons/People';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import HomeIcon from '@material-ui/icons/Home';
import TimelineIcon from '@material-ui/icons/Timeline';

export const mookCards = [
  { 
    img: 'https://gilbertybolona.com/wp-content/uploads/2018/01/seguro-vida.png',
    icon: FavoriteBorderIcon,
    title: 'Seguro de Vida',
    text: 'Garantiza a quienes dependen de ti, un futuro lleno de certezas en caso de que no estés.',
    url: 'https://gilbertybolona.com/service/seguro-vida/'
  },
  { 
    img: 'https://gilbertybolona.com/wp-content/uploads/2018/01/seguro-salud.png',
    icon: PeopleIcon,
    title: 'Seguro de Salud',
    text: 'Cubre gastos de hospitalización y tratamientos relacionados a enfermedades o condiciones médicas.',
    url: 'https://gilbertybolona.com/service/seguro-salud/'
  },
  { 
    img: 'https://gilbertybolona.com/wp-content/uploads/2018/01/seguro-viajes.png',
    icon: FlightTakeoffIcon,
    title: 'Seguro de Viajes',
    text: 'Cubre un mínimo de asistencia médica y coberturas básicas ante cualquier incidente que ocurra durante tu viaje.',
    url: 'https://gilbertybolona.com/service/seguro-viajes/'
  },
  { 
    img: 'https://gilbertybolona.com/wp-content/uploads/2018/01/seguro-carro.png',
    icon: DriveEtaIcon,
    title: 'Seguro de Auto',
    text: 'Cubre los gastos del daño de tu vehículo, y ofrece servicios extra cuando se amerita.',
    url: 'https://gilbertybolona.com/service/seguro-autos/'
  },
  { 
    img: 'https://gilbertybolona.com/wp-content/uploads/2018/01/seguro-vivienda-1.png',
    icon: HomeIcon,
    title: 'Seguro de Vivienda',
    text: 'Cubre los gastos de daños estructurales del inmueble y determinados elementos dentro de la vivienda.',
    url: 'https://gilbertybolona.com/service/seguro-vivienda/'
  },
  { 
    img: 'https://gilbertybolona.com/wp-content/uploads/2018/01/seguro-empresa.png',
    icon: TimelineIcon,
    title: 'Seguro Empresarial',
    text: 'Ideado para amparar la estructura de la compañía contra situaciones de desastre.',
    url: 'https://gilbertybolona.com/service/seguro-empresarial/'
  },
];