const {clientAxios}= require('../config/axios')

export const odooCreateLead = async (body) => {
    try{
        const response = null // await clientAxios.post("https://1at41irezb.execute-api.us-west-2.amazonaws.com/dev/odoo-leads", JSON.stringify(body))
        return response
    } catch (error) {
        return {
            error
        }
    }
}

export const odooCreateTicket = async (body) => {
    try{
        const response = await clientAxios.post("https://1at41irezb.execute-api.us-west-2.amazonaws.com/dev/odoo-tickets", JSON.stringify(body))
        return response
    } catch (error) {
        return {
            error
        }
    }
}