import { useEffect, useState } from 'react';
import { makeStyles, Button } from '@material-ui/core'
// import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PeopleIcon from '@material-ui/icons/People';
import FaceIcon from '@material-ui/icons/Face';
// import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

import { ButtonPrimary, OpenComparativePanel, PeriodButton } from './../ui/Buttons';

const useStyles = makeStyles(theme => ({
  buttonGroups: {
    display: ({ activeStep }) => activeStep === 0 ? 'none' : null,
    textAlign: 'center',
    marginBotton: 50
  },
  PeriodButton: {
    maxWidth: "80% !important"
  },
  comparativeGroup: {
    display: ({ activeStep }) => activeStep === 0 ? 'none' : null,
    textAlign: 'center',
    marginBotton: 50
  },
}))

export const ButtonGroups = ({ activeStep, activate, handleSetActivate, handleDeleteBeneficiaries }) => {

  const classes = useStyles({ activeStep });

  const changeStatusMarried = () => ({ marriedActivated: !activate.marriedActivated })
  const changeStatusSoons = () => ({ soonsActivated: !activate.soonsActivated })

  const handleClickSpouse = (e) => {
    handleSetActivate(e)
    handleDeleteBeneficiaries({
      spouse: '',
      spouseAge: ''
    })
  }

  const handleClickChildren = (e) => {
    handleSetActivate(e)
    handleDeleteBeneficiaries({
      children:''
    })
  }

  const showButtons = () => {
    if (activeStep === 1) {
      return (
        <>
          {/* <ButtonPrimary
            icon={PeopleIcon}
            title="¿soltero?"
            status={activate.marriedActivated}
            value={changeStatusMarried()}
            handleClick={handleSetActivate}
          /> */}
          <ButtonPrimary
            icon={PeopleIcon}
            title="¿casado?"
            status={activate.marriedActivated}
            value={changeStatusMarried()}
            handleClick={handleClickSpouse}
          />
          <ButtonPrimary
            icon={FaceIcon}
            title="¿hijos?"
            status={activate.soonsActivated}
            value={changeStatusSoons()}
            handleClick={handleClickChildren}
          />
        </>
      )
    }
  }

  return (
    <div className={classes.buttonGroups}>
      {showButtons()}
    </div>
  )
}

export const PeriodButtons = ({ 
    handleChangePeriod, 
    period, 
    prod, 
    insurer, 
    PersonalQuote,
    handleChangeTotalButtons,
    handleQuotingAgain,
    addDisabled
  }) => {
  const build_action_button_group = () => {
    var indexes = Object.keys(period)
    var result = {}
    for (let x in indexes) {
      if (x == 0) {
        result[x] = true
      }
      else result[x] = false
    }
    return result
  }
  const values = build_action_button_group()
  const [indexes, setIndexes] = useState(values)
  const classes = useStyles();

  const toggleClass = (change) => {
    setIndexes(change)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup disableElevation
        variant="contained"
        aria-label="small button group"
        className={classes.PeriodButton}
      >
        {
          period.map((pod, ind) => (
            <PeriodButton
              key={pod+ind}
              status={indexes[ind]}
              period={pod}
              indexes={indexes}
              index={ind}
              handleSetActive={toggleClass}
              prod={prod}
              insurer={insurer}
              addDisabled={addDisabled}
              handleChangePeriod={handleChangePeriod}
              PersonalQuote={PersonalQuote}
              handleChangeTotalButtons={handleChangeTotalButtons}
              handleQuotingAgain={handleQuotingAgain}
            />
          )
          )
        }
      </ButtonGroup>
    </Box>
  )
}

export const ComparativePanelButtons = ({ activeStep, handleNext}) => {
  const classes = useStyles();
  return (
    <div className={classes.comparativeGroup}>
      <OpenComparativePanel
        buttonAction={handleNext}
      />
    </div>
  )
}
