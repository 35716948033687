import { makeStyles, Typography, Divider, Grid, Button } from '@material-ui/core';
import { useEffect, useContext } from 'react';
import { DeleteButtonPlanes, OpenComparativeSummary } from '../ui/Buttons'
import { dataProvider } from './../../context/data/dataProvider'

const useStyles = makeStyles(theme => ({
  summary: {
    height: 'auto',
    border: '1px solid transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 9px -2px rgb(0 0 0 / 90%)',
    borderBottomLeftRadius: '40px'
  },
  summary__title: {
    textAlign: 'center',
    margin: '10px 0 20px',
    color: 'white'
  },
  summary__content: {
    padding: '0 20px',
  },
  summary__content_description: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    color: 'white',

    '&:last-child': {
      marginBottom: 0,
    }
  },
  divider: {
    backgroundColor: '#929292',
    marginTop: '30px'
  },
  dividerSummary: {
    backgroundColor: '#929292',
    margin: '15px'
  },
  summary__total: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px 5px',
    color: 'white'
  },
  summary__description_plan: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 5,
    color: 'white',
    '& .MuiTypography-root ': {
      margin: "auto",
      width: 100
    },
    '&:last-child': {
      marginBottom: 0,
    }
  },
  summary__content__planes: {
    textAlign: "center",
    '& .MuiTypography-h6': {
      marginBottom: 10
    }
  },
  summary__content__compare: {
    margin: '20px !important',
    textAlign: "center",
    '& .MuiTypography-h6': {
      marginTop: 10
    }
  }
}))

const Summary = (props) => {
  const classes = useStyles();

  const {
    holder,
    holderAge,
    cedula,
    phone,
    spouse,
    spouseAge,
    children,
    olderChildren,
    planes,
    activeStep,
    handleNext,
    handleDeleteSelectedPlan
  } = props;

  console.log('prosp => ', props);

  const { data } = useContext(dataProvider)
  console.log("planes selecct ", planes)

  console.log('data provider => ', data);
  const handleOlderChildrenCount = (olderChildren) => {
    var count = 0
    for (let x in olderChildren) {
      if (olderChildren[x] != "" && parseInt(olderChildren[x]) >= 24) count++
    }
    return count
  }


  return (
    <div className={classes.summary}>
      <div className={classes.summary__content}>
        <div className={classes.summary__title}>
          <Typography variant="h6" color="initial">Cotización</Typography>
        </div>
        {
          holder && (
            <div className={classes.summary__content_description}>
              <Typography variant="subtitle1" color="initial">Titular</Typography>
              <Typography variant="subtitle1" color="initial">{`${holder} ${holderAge != null ? '(' + holderAge + ')' : ''}`}</Typography>
            </div>
          )
        }

        {
          cedula && (
            <div className={classes.summary__content_description}>
              <Typography variant="subtitle1" color="initial">Cedula</Typography>
              <Typography variant="subtitle1" color="initial">{cedula}</Typography>
            </div>
          )
        }
        {
          phone && (
            <div className={classes.summary__content_description}>
              <Typography variant="subtitle1" color="initial">Celular</Typography>
              <Typography variant="subtitle1" color="initial">{phone}</Typography>
            </div>
          )
        }
        {
          spouse && (
            <div className={classes.summary__content_description}>
              <Typography variant="subtitle1" color="initial">Cónyuge</Typography>
              <Typography variant="subtitle1" color="initial">{`${spouse} ${spouseAge != null ? '(' + spouseAge + ')' : ''}`}</Typography>
            </div>
          )
        }
        {
          children && (
            <div className={classes.summary__content_description}>
              <Typography variant="subtitle1" color="initial">Hijos menores a 24 años</Typography>
              <Typography variant="subtitle1" color="initial">{children}</Typography>
            </div>
          )
        }
        {
          olderChildren != null && (
            <div className={classes.summary__content_description}>
              <Typography variant="subtitle1" color="initial">Hijos mayores a 24 años</Typography>
              <Typography variant="subtitle1" color="initial">{handleOlderChildrenCount(olderChildren)}</Typography>
            </div>
          )
        }
        <Divider variant="middle" className={classes.divider} />


      </div>
      {
        activeStep == "2" && (
          <div className={classes.summary__total}>
            {
              planes == null ? (
                <>
                  <Typography variant="h6" color="initial" >Selecciona tu plan ideal</Typography>
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid item className={classes.summary__content__planes}>
                      <Typography variant="h6" color="initial">Planes seleccionados</Typography>
                      {
                        planes.map((plan, index) => {
                          return (
                            <div className={classes.summary__description_plan} key={"plan-" + index}>
                              <Typography color="initial">{plan.insurer}</Typography>
                              <Typography color="initial">{plan.name}</Typography>
                              <Typography color="initial">{plan.value}</Typography>
                              {/* <DeleteButtonPlanes 
                          handleClick={handleDeleteSelectedPlan}
                          jump={index}
                          planes={planes}/> */}
                            </div>
                          )
                        })
                      }
                      <Divider variant="middle" className={classes.dividerSummary} />
                      <OpenComparativeSummary
                        buttonAction={handleNext}
                      />
                    </Grid>
                  </Grid>
                </>
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default Summary
