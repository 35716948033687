import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { React, useEffect, useState, useContext } from "react";
import { dataProvider } from "./../../context/data/dataProvider";
import { CustomizedTables } from "../ui/Table";
import { AutocompleteGroups } from "../containers/AutocompleteGroups";
import { height } from "@mui/system";
import GetAppIcon from "@material-ui/icons/GetApp";
import { sendMessageSNS } from "../../third_party_api/sns";
import TabPanel from "../ui/Tabs";
import { ButtonDownloadReport, ButtonTableViewReport } from "../ui/Buttons";
import { awsBenefits } from "../../third_party_api/aws";

const useStyles = makeStyles((theme) => ({
  comparative: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",

    "& .MuiGrid-item": {
      [theme.breakpoints.down("xs")]: {
        flexBasis: "100%",
        maxWidth: "100%",
      },
    },
  },
  bottonSectionComparative: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
  },

  grid__item_download_quoter: {
    textAlign: "left",
    margin: "10px 0px",
    /*  backgroundColor: '#7c0837',
        padding: '8px',
        borderRadius: '8px',
        width: '13.2em',
        color: 'white',
        textTransform: 'uppercase',
        cursor: 'pointer',
        letterSpacing: '1.8rem', */
  },

  tableSectionComparative: {
    marginTop: "20px",
    justifyContent: "center",
  },

  paperComparative: {
    marginTop: "30px",
    paddingBottom: "30px",
    paddingTop: "30px",
  },

  gridComparativeLength: {
    margin: "auto",
  },
}));

const Comparative = ({
  PersonalQuote,
  cardDetail,
  planes,
  summarycomparativePlan,
  comparativeTotals,
  comparativeInsurer
}) => {
  const classes = useStyles({});
  const [comparativeLength, setComparativeLength] = useState(5);
  const [comparativePlan, setComparativePlan] = useState([]);
  const [selectedPlanByInsurer, setSelectedPlanByInsurer] = useState([]);
  const [specificBalanceForPlan, setSpecificBalanceForPlan] = useState([]);
  const [selectedTotalByDeductible, setSelectedTotalByDeductible] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [comparativePlanSelect, setComparativePlanSelect] = useState([]);
  const [urlPdf, setUrlPdf] = useState("");
  const [text, setText] = useState("Generar el documento");

  const { data } = useContext(dataProvider);
  console.log('data provider comparative => ', data);

  let newSelectedPlanByInsurer = [];
  let newComparativePlan = [];

  useEffect(() => {
    console.log("Personal Quote ", PersonalQuote)
    if (comparativePlan.length === 0) {
      setComparativePlan(summarycomparativePlan);
      setSelectedPlanByInsurer(comparativeInsurer);
      setSelectedTotalByDeductible(comparativeTotals)
    }
    // for (let index = 0; index < planes.length; index++) {
    //   handleComparativeDeductible(planes[index].deductible, index);
    // }
  }, [comparativePlan]);

  function handleComparativePlan(valueC) {
    setComparativePlan(valueC);
    setComparativePlanSelect(valueC);
  }

  function handleSelectedPlanByInsurer(valueI) {
    setSelectedPlanByInsurer(valueI);
  }

  const handleSelectedPlan = (detail, plan, ind, indexes) => {
    if (detail != undefined) {
      newComparativePlan = [...comparativePlan];
      newComparativePlan[indexes] = plan;
      newSelectedPlanByInsurer = [...selectedPlanByInsurer];
      const quoter = PersonalQuote["result"][detail.icode][detail.code];
      quoter["info"] = {
        icode: detail.icode,
        code: detail.code,
      };
      newSelectedPlanByInsurer[indexes] = quoter;

      handleSelectedPlanByInsurer(newSelectedPlanByInsurer);
      handleComparativePlan(newComparativePlan);
      handleComparativeDeductible(planes[ind].deductible, ind);
    }
  };

  const handleGetPlanOptionNames = () => {
    const info = PersonalQuote["info"];
    const insurer = [];
    const plan = [];
    for (const [key, value] of Object.entries(info)) {
      const data = value;
      const cinsurer = key;
      for (const [key, value] of Object.entries(data)) {
        if (!insurer.includes(value.insurer)) insurer.push(value.insurer);
        plan.push({
          plan: value.name,
          insurer: value.insurer,
          code: key,
          icode: cinsurer,
        });
      }
    }
    return { insurer, plan };
  };

  const handleComparativeDeductible = (deductible, ind) => {
    if (selectedPlanByInsurer.length > 0) {
      setTimeout(() => {
        let newSelectedPlanByInsurer = [...selectedTotalByDeductible];
        newSelectedPlanByInsurer[ind] = selectedPlanByInsurer[ind][deductible];
        console.log("newSelectedPlanByInsurer 1=> ", newSelectedPlanByInsurer);
        setSelectedTotalByDeductible(newSelectedPlanByInsurer);
      }, 1000);
    }
  };

  const handleComparativePlanComboBox = (data) => {
    const resp = handleGetPlanOptionNames();
    console.log("resp => ", resp);
    console.log("data => ", data);
    console.log(comparativeLength);
    const plan = [];
    if (comparativeLength <= 5 && comparativeLength != 1) {
      for (let prod = 0; prod < comparativeLength; prod++) {
        if (prod < data.length) {
          plan.push(
            <AutocompleteGroups
              key={prod}
              indexes={prod}
              selectedPlanAction={handleSelectedPlan}
              insurerNames={resp.insurer}
              planNames={resp.plan}
              PersonalQuote={PersonalQuote}
              selectedDeductibleAction={handleComparativeDeductible}
              namePlans={data[prod].name}
              nameInsurers={data[prod].insurer}
              deductible={data[prod].deductible}
            />
          );
        } else {
          plan.push(
            <AutocompleteGroups
              key={prod}
              indexes={prod}
              selectedPlanAction={handleSelectedPlan}
              insurerNames={resp.insurer}
              planNames={resp.plan}
              PersonalQuote={PersonalQuote}
              selectedDeductibleAction={handleComparativeDeductible}
              namePlans={""}
              nameInsurers={""}
              deductible={""}
            />
          );
        }
      }
    }
    return plan;
  };
  // const handleDownload = async () => {
  //const response = await awsBenefits()
  // console.log("data clic ", data);
  //  const params={
  //   body: data,
  //     arn: 'arn:aws:sns:us-west-2:441079406481:fileGenerator-dev'
  //   }
  //   return "OK "; //await sendMessageSNS(params)
  // };

  const handleDownload = async () => {

    let idContact = ""
    if (urlPdf === "") {
      const benefitsByInsurer = []
      const tableTotal = []
      for (let idx = 0; idx < planes.length; idx++) {
        const body = {insurer: planes[idx].insurer.toUpperCase(), plan: planes[idx].name.toUpperCase()}
        console.log("body ", body)
        const response =  await awsBenefits(body)
        benefitsByInsurer.push(response.data)
        const deductible = planes[idx].deductible
        tableTotal.push( selectedPlanByInsurer[idx][deductible])
      }
      const bodyPdf = {
        holderName: data.holderName,
        region: data.region,
        contact_id: data.contact_id,
        holderAge: data.holderAge,
        phoneNumber: data.phoneNumber,
        email: data.email,
        spouseAge: data.spouseAge,
        spouse: data.spouse,
        children: data.children,
        olderChildren: data.olderChildren,
        planes: data.planes,
        benefits: benefitsByInsurer,
        tableData: tableTotal
      }
      const params={
          body: bodyPdf,            
          arn: 'arn:aws:sns:us-west-2:441079406481:fileGenerator-dev'
      }
      idContact = bodyPdf.contact_id
      console.log("data clic ", params) 
      await sendMessageSNS(params) 
      setTimeout(()=>{
        const url = "https://gyb-quotes-store-production.s3.us-west-2.amazonaws.com/quotes/gyb-Cotizacion-"+idContact+".pdf"
        setUrlPdf(url)
        setText("Descargar")
      },10000)
      }     
     //https://gyb-quotes-store-production.s3.us-west-2.amazonaws.com/quotes/gyb-Cotizacion-c2c6ef2e-a6cb-4554-ba6a-0249a1722a42.pdf
      return urlPdf
    };

  const handleViewTableComparative = (value) => {
    for (let index = 0; index < value.length; index++) {
      const detail = value[index];
      const plan = value[index].name;
      console.log(
        "datail for -> ",
        detail,
        " plan for =>",
        plan,
        " index ",
        index
      );
      handleComparativeDeductible(value[index].deductible, index);
      //handleSelectedPlan(detail, plan, index)
    }
  };

  return (
    <div className={classes.comparative}>
      {/* <Grid item  
                className={classes.gridComparativeLength}
                xs={6} sm={6} md={6} lg={6}>
            <TextField
                    id="compareLength"
                    name="compareLength"
                    label="Cantidad de productos a comparar"
                    variant="outlined"
                    fullWidth
                    placeholder="Ej. 3"
                    helperText = {comparativeLength<=5 || comparativeLength=='' ?
                    comparativeLength==1?"Debe comparar con al menos 2 planes":null
                    :"Puede comparar hasta con 5 planes"}
                    FormHelperTextProps={{ style: {'color': '#7c0837', 'fontSize': '.8em'}}}
                    onChange={handleComparativeLength}
                    value={comparativeLength}
                />
            </Grid> */}
      {planes.length >= 2 && planes.length <= 5 && (
        <div>
          {!isPrinting && (
            <Paper className={classes.paperComparative} elevation={3}>
              <Grid item className={classes.bottonSectionComparative}>
                {handleComparativePlanComboBox(planes)}
              </Grid>
            </Paper>
          )}
          { }
          <Grid item className={classes.grid__item_download_quoter}>
            <ButtonDownloadReport 
            handleClick={() => handleDownload()}
            pdfUrl = {urlPdf}
            text={text} />
            <ButtonTableViewReport
              handleClick={() => handleViewTableComparative(planes)}
            />
          </Grid>
          <Grid item className={classes.tableSectionComparative}>
            {comparativeLength <= 5 &&
              comparativeLength != 1 &&
              comparativeLength >= 2 && (
                <CustomizedTables
                  comparativeLength={comparativeLength}
                  comparativePlan={comparativePlan}
                  selectedPlanByInsurer={selectedPlanByInsurer}
                  selectedTotalByDeductible={selectedTotalByDeductible}
                  comparativePlanSelect={comparativePlanSelect}
                />
              )}
          </Grid>
        </div>
      )}
    </div>
  );
};
export default Comparative;
