import { makeStyles, Grid } from '@material-ui/core';
import Slider from "react-slick";
import Backdrop from '@mui/material/Backdrop';
import { PaymentCard } from '../ui/Cards';
import { QuotingLottieMotion } from '../ui/LottieMotion';
const useStyles = makeStyles(theme => ({
  finalStep: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .slick-slider': {
      '& .slick-prev': {
        display: 'none !important'
      },
      '& .slick-next': {
        display: 'none !important'
      },
    },
    '& .slick-track': {
      display: 'flex',

      '& .slick-slide': {
        marginLeft: 15
      }
    }
  }
}))


const FinalStep = ({ 
  PersonalQuote, 
  cardDetail, 
  handleSelectPlan, 
  handleChangePeriod, 
  handleChangeQuote, 
  loading,
  localLoading,
  error,
  planes,
  somePlanWasDeleted,
  handleDeleteSelectedPlan
}) => {
  const classes = useStyles();

  //console.log("cardDetail ", cardDetail)

  const configSlider = {
    infinite: true,
    speed: 500,
    slidesToShow:  4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 23
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const View = QuotingLottieMotion(error, localLoading)

  return (
    <div className={classes.finalStep}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sm={12} lg={12} style={{ height: '120%' }}>
          <Slider {...configSlider}>
            {
              cardDetail.map((quote, index) => {
                const insurer = Object.keys(quote['result'])[0]
                const moreDetails = Object.values(quote['result'])[0]
                const products = Object.keys(moreDetails)
                const deductibles = Object.values(moreDetails)
                 return (
                  products.map((prod, index) => {
                    //console.log("PersonalQuote ", PersonalQuote)
                    const product_complete_name = PersonalQuote['info'][insurer][prod]['name']
                    const insurer_complete_name = PersonalQuote['info'][insurer][prod]['insurer']
                    const _period = PersonalQuote['info'][insurer][prod]['period']
                    const maternity = PersonalQuote['info'][insurer][prod]['maternity']!=undefined?PersonalQuote['info'][insurer][prod]['maternity']:0
                    return (
                      <PaymentCard
                        key={index}
                        type="free"
                        title={prod}
                        prod_name = {product_complete_name}
                        insurer_name = {insurer_complete_name}
                        deductibles={deductibles[index][0]}
                        subTitle={insurer}
                        handleChangePeriod={handleChangePeriod}
                        preferencialAmount={quote['amount'][insurer][prod]}
                        handleClick={handleSelectPlan}
                        PersonalQuote={PersonalQuote}
                        period={_period}
                        maternity={maternity}
                        handleChangeQuote={handleChangeQuote}
                        planes={planes}
                        handleDeleteSelectedPlan={handleDeleteSelectedPlan}
                        somePlanWasDeleted={somePlanWasDeleted}
                      />
                    )
                  })
                )
              })
            }
          </Slider>
          {
          loading && (
            <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <Grid item xs={12}>
              {View}
            </Grid>
          </Backdrop>
          )
        }
        </Grid>
      </Grid>
    </div>
  )
}

export default FinalStep
