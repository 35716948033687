import {
  SET_START,
  SET_STEPPER,
  SET_HIDDEN,
  SET_ACTIVE
} from './../types';

export const initialState = {
  start: true,
  hidden:false,
  activeStep: 0,
  activate: {
    marriedActivated: false,
    soonsActivated: false,
    olderActivated: false
  }
}

export const appReducer = (state = initialState, actions) => {
  switch (actions.type) {

    case SET_START:
      return {
        ...state,
        start: true
      }

    case SET_HIDDEN:
      return {
        ...state,
        hidden: actions.payload
      }

    case SET_STEPPER:
      return {
        ...state,
        activeStep: actions.payload
      }

    case SET_ACTIVE:
      return {
        ...state,
        activate: { ...state.activate, ...actions.payload }
      }
  
    default:
      return state;
  }
}