import React, { useEffect, useState } from 'react';
import { makeStyles, TextField, InputAdornment, Grid, Typography, Checkbox } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import EventIcon from '@material-ui/icons/Event';
import FaceIcon from '@material-ui/icons/Face';
import Backdrop from '@mui/material/Backdrop';
import {AddButtonChildren, DeleteButtonChildren} from '../ui/Buttons'
import { QuotingLottieMotion } from '../ui/LottieMotion';
import { VideoAskPendingModal, CreatingOdooResponse } from '../ui/Modal'
import { ComboBoxProvincias } from '../ui/Autocomplete'

const useStyles = makeStyles(theme => ({
  secondStep: {
    display: 'block',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    padding: '0 30px',
    marginTop: '50px',

    '& .MuiGrid-item': {
      [theme.breakpoints.down("xs")]: {
        flexBasis: "100%",
        maxWidth: "100%"
      }
    }
  },
  titleSons: {
    paddingBottom: '10px',
    fontSize: '14px',
    fontFamily: 'inherit'
  },
  addChildren: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0',

    '& .MuiIconButton-root': {
      padding: '0 5px 0 0',
      color: ({ checkOlderChildren }) => checkOlderChildren ? '#9f0744' : null,
    },
    '& span': {
      fontSize: '14px'
    }
  },
  SonSection: {
    padding: '10px'
  },
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
  grid__container__children: {
    overflow: 'scroll'
  },
  errorInputText: {
    '& label.Mui-focused': {
      color: '#7c0837',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#7c0837',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#7c0837',
      },
      '&:hover fieldset': {
        borderColor: '#7c0837',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#7c0837',
      },
    },
  },
  childrenContainer: {
    marginBottom: "15px",

    '& .MuiGrid-item': {
      [theme.breakpoints.down("xs")]: {
        flexBasis: "80%",
      }
    }
  }
}))

const SecondStep = ({ activate, 
                      inputs, 
                      handleChangeState, 
                      handleSetActivate, 
                      handleOlderChildren,
                      loading,
                      errorQuoting,
                      infoComplete,
                      localLoading,
                      userData,
                      openQuoteModal,
                      ticketCreated,
                      handleContinueCompleteForm,
                      handleCreateTicketOdoo,
                      validateEmail,
                      validatePhoneNumber,
                      handleChangeProvince
                    }) => {
  
  const View = QuotingLottieMotion(errorQuoting, localLoading)

  const [checkOlderChildren, setcheckOlderChildren] = useState(false)
  const [region, setRegion] = useState(false)
  const classes = useStyles({ checkOlderChildren });

  const handleChangeOlderActivated = (e) => {
    setcheckOlderChildren(e.target.checked)
    handleSetActivate({olderActivated: e.target.checked })
    handleOlderChildren([])
  }

  const handleAddOlderChildren = () => {
    handleOlderChildren([...inputs.olderChildren, ""]);
  };
  
  const deleteOlderChildren = (index) => {
    handleOlderChildren(inputs.olderChildren.filter((j,i) => index!==i ));
  };

  const handleChangeOlderChildren = (index, e) => {
    const updateAges = inputs.olderChildren.map((value, i) => {
      if (i === index) {
        return e.target.value;
      } else {
        return value;
      }
    });
    handleOlderChildren(updateAges)
  }

  useEffect(()=>{
    setcheckOlderChildren(activate.olderActivated)
    if (inputs.region==='') setRegion(true)
  },[])



  return (
    <div className={classes.secondStep}>
        <Grid container justifyContent="center" spacing={3}>
        { region && 
            <Grid item xs={12} sm={12} md={12}>
              <ComboBoxProvincias
                handleChangeProvince={handleChangeProvince}
              />
            </Grid>
          } 
          { !loading && 
            <Grid item xs={6} sm={6} md={6}>
              <TextField
                id="holderName"
                name="holderName"
                label="Nombre del titular"
                variant="outlined"
                size="small"
                fullWidth
                placeholder="Ej. Francisco Morales"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                  readOnly: infoComplete
                }}
                value={inputs.holderName !== '' ? inputs.holderName : ''}
                onChange={handleChangeState}
              />
            </Grid>
          } 
          { !loading && 
          <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    className={inputs.holderAge>=18 || inputs.holderAge=='' ?classes.input:[classes.errorInputText,classes.input]}
                    type="number"
                    id="holderAge"
                    name="holderAge"
                    label="Edad del titular"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Ej. 32"
                    onInput = {(e) =>{
                      e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                      }
                    }
                    helperText = {inputs.holderAge>=18 || inputs.holderAge=='' ?null:"Edad minima 18 años"}
                    FormHelperTextProps={{ style: {'color': '#7c0837', 'fontSize': '.8em'}}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventIcon />
                        </InputAdornment>
                      ),
                      readOnly: infoComplete
                    }}
                    value={inputs.holderAge}
                    onChange={handleChangeState}
                  />
            </Grid>  
          }  
          {
            activate.marriedActivated && !loading &&(
              <>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    id="spouse"
                    name="spouse"
                    label="Nombre del cónyuge (Opcional)"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Ej. Maitte Garcia"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PeopleIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={inputs.spouse}
                    onChange={handleChangeState}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <TextField
                    className={inputs.spouseAge>=18 || inputs.holderAge=='' ?classes.input:[classes.errorInputText,classes.input]}
                    type="number"
                    id="spouseAge"
                    name="spouseAge"
                    label="Edad del cónyuge"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Ej. 32"
                    onInput = {(e) =>{
                      e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                      }
                    }
                    helperText = {inputs.spouseAge>=18 || inputs.spouseAge=='' ?null:"Edad minima 18 años"}
                    FormHelperTextProps={{ style: {'color': '#7c0837', 'fontSize': '.8em'}}}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EventIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={inputs.spouseAge}
                    onChange={handleChangeState}
                  />
                </Grid>
              </>
            )
          }

          {
            activate.soonsActivated && !loading && (
              <>
                <Grid item xs={12} className={classes.SonSection}>
                  <Typography className={classes.titleSons}>
                    Hijos menores a 24 años
                  </Typography>
                  <TextField
                    className={classes.input}
                    type="number"
                    id="children"
                    name="children"
                    label="Hijos/as"
                    variant="outlined"
                    size="small"
                    fullWidth
                    placeholder="Ej. 2"
                    onInput = {(e) =>{
                      e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                      }
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FaceIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={inputs.children}
                    onChange={handleChangeState}
                  />
                  <div className={classes.addChildren}>
                    <Checkbox
                      checked={checkOlderChildren}
                      onChange={handleChangeOlderActivated}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <span>Tienes hijos mayores a 24 años?</span>
                  </div>
                </Grid>
              </>
            )
          }
          {
            activate.olderActivated && activate.soonsActivated && !loading &&(
              <>
                <Grid item xs={12} sm={12} md={12} 
                  className={classes.grid__container__children}
                >
                  {
                    inputs.olderChildren.map((jump, index) => (
                        <Grid container key={"children" + index} className={classes.childrenContainer} >
                            <Grid item xs={6} sm={6} md={6}>
                              <TextField
                                className={jump>=24 || jump=='' ?classes.input:[classes.errorInputText,classes.input]}
                                type="number"
                                id="olderChildren"
                                name="olderChildren"
                                label="Edad" 
                                variant="outlined"
                                size="small"
                                fullWidth
                                helperText = {jump>=24 || jump=='' ?null:"Edad minima 24 años"}
                                FormHelperTextProps={{ style: {'color': '#7c0837', 'fontSize': '.8em'}}}
                                placeholder="Ej. 24"
                                onInput = {(e) =>{
                                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                                  }
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FaceIcon />
                                    </InputAdornment>
                                  ),
                                }}
                                value={jump || ""}
                                onChange={(e)=>handleChangeOlderChildren(index,e)}
                              />
                            </Grid>
                            {/* <Grid item xs={6} sm={1} md={1}> */}
                              <DeleteButtonChildren
                                jump = {jump}
                                  handleClick={()=>deleteOlderChildren(index)}/>
                            {/* </Grid> */}
                        </Grid>
                      ))
                  }
                  { inputs.olderChildren.length<10 && (
                      <AddButtonChildren
                      handleClick={handleAddOlderChildren}
                      />
                    )
                  }
                </Grid>
              </>
            )
          }
        { openQuoteModal && (
          <VideoAskPendingModal
            userData={userData}
            infoComplete={infoComplete}
            handleChangeState={handleChangeState}
            handleContinueCompleteForm={handleContinueCompleteForm}
            handleCreateTicketOdoo={handleCreateTicketOdoo}
            validatePhoneNumber={validatePhoneNumber}
            validateEmail={validateEmail}
            />
          )
        }
        {
          ticketCreated && (
            <CreatingOdooResponse
            />
          )
        }

        
        {
          loading && (
            <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <Grid item xs={12}>
                  {View}
            </Grid>
          </Backdrop>
          )
        }
      </Grid>
    </div>
  )
}

export default SecondStep
