import { React, useEffect, useState } from 'react';
import {Grid, Typography } from '@material-ui/core';
import { QuotingLottieMotion } from '../ui/LottieMotion'

import Backdrop from '@mui/material/Backdrop';

const LoadingPage = ({loading, error, localLoading, handleGetInfoCustomer}) => {

  useEffect(() => {
    setTimeout(async () => {
      if (loading) {
        await handleGetInfoCustomer()
      }
    }, 2000);
  },[]);

  const View = QuotingLottieMotion(error, localLoading)

  return (
    <div>
       <Grid container justifyContent="center" spacing={2}>
         {
           loading && (
             <Backdrop
             sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
             open={loading}
           >
             <Grid item xs={12}>
             {View}
             </Grid>
           </Backdrop>
           )
         }
        {
          error && (
            <Grid>
                {View}
              <Typography style={{"textAlign": "center"}}>
                Hemos detectado un problema al momento de generar tu cotización, <br/> por favor envia un correo electrónico a <b>ventas@gilbertybolona.com</b> con tus datos personales <br/> para que uno de nuestros ejecutivo pueda atender tu caso..
              </Typography>
            </Grid> 
          )
        }
       </Grid>
     </div> 
  )
}

export default LoadingPage
