import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@material-ui/core';
import { CardDetailTitulares } from '../../utils/CardDetail'



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#9f0844',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const useStyles = makeStyles(theme => ({
  tableHead: {
    backgroundColor: '#9f0844',
  },
  tableHeadCells: {
    color: 'white !important',
  }
}))

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export const CustomizedTables = ({ comparativeLength, comparativePlan, selectedPlanByInsurer, selectedTotalByDeductible, comparativePlanSelect }) => {
  const classes = useStyles({});
  const [rows, setRows] = React.useState([])

  React.useEffect(() => {
    console.log('COMPARATIVE TABLE ', comparativePlan)
    if (comparativeLength > 0) {
     // if (comparativePlanSelect.length > 0) {
          const rows = makeRowsFromBody()
          console.log('FINAL ROWS ', rows)
          //comparativePlan = comparativePlanSelect
          setRows(rows)
      //}
     
    } else {
      setRows([])
    }
  }, [comparativePlan, selectedTotalByDeductible])

  function makeColumnsFromHeader(plan, index) {
    console.log('TABLE CELL =>', plan)
    if (plan !== undefined) {
      return (
        <StyledTableCell
          key={index}
          align="right"
        >
          {plan}
        </StyledTableCell>
      )
    }
  }

  function makeRowsFromBody() {
    const rows = []
    const labels = CardDetailTitulares("anual", "all")
    if (selectedTotalByDeductible.length > 0) {
      labels.map((item, value) => {
        const row = {
          name: item
        }
        if (item == 'Subtotal') {
          selectedTotalByDeductible.map((item, value) => {
            console.log('FINAL ITEM ', item)
            console.log('FINAL VALUE ', value)
            console.log('FINAL FETCH ', selectedPlanByInsurer)
            if (selectedPlanByInsurer[value] && item) {
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = item['subtotal']
                  break
                case 'bd':
                  row[value] = item['subtotal']
                  break
                case 'bupa':
                  row[value] = item['subtotal']
                  break
              }
            }
          })
        }
        else if (item == 'Seguro Campesino (0,5%)' ) {
          if (selectedPlanByInsurer[value]) {
            selectedTotalByDeductible.map((item, value) => {
              if (selectedPlanByInsurer[value] && item) {
                console.log('10> ', selectedPlanByInsurer[value]['info']['icode'])
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = item['campesino']
                  break
                case 'bd':
                  console.log('item1 ', item['campesino'])
                  row[value] = item['campesino']
                  break
                case 'bupa':
                  row[value] = item['campesino']
                  break
                }
              }
            })
          }
        }
        else if (item == 'Superintendencia de Bancos (3,5%)') {
          selectedTotalByDeductible.map((item, value) => {
            if (selectedPlanByInsurer[value] && item) {
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = item['superBancos']
                  break
                case 'bd':
                  row[value] = null
                  break
                case 'bupa':
                  row[value] = item['superBancos']
                  break
              }
            }
          })
        }
        else if (item == 'Total') {
          selectedTotalByDeductible.map((item, value) => {
            if (selectedPlanByInsurer[value] && item) {
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = item['contado']
                  break
                case 'bd':
                  row[value] = item['contado']
                  break
                case 'bupa':
                  row[value] = item['contado']
                  break
              }
            }
          })
        }
        else if (item == 'Valor Contado') {
          selectedTotalByDeductible.map((item, value) => {
            if (selectedPlanByInsurer[value] && item) {
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = item['contado']
                  break
                case 'bd':
                  row[value] = item['contado']
                  break
                case 'bupa':
                  row[value] = item['contado']
                  break
              }
            }
          })
        }
        else if (item == 'Valor Financiado') {
          selectedTotalByDeductible.map((item, value) => {
            if (selectedPlanByInsurer[value] && item) {
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = null
                  break
                case 'bd':
                  row[value] = null
                  break
                case 'bupa':
                  row[value] = null
                  break
              }
            }
          })
        }
        else if (item == 'Diferido Especial') {
          selectedTotalByDeductible.map((item, value) => {
            if (selectedPlanByInsurer[value] && item) {
              switch (selectedPlanByInsurer[value]['info']['icode']) {
                case 'bmi':
                  row[value] = item['diferido']
                  break
                case 'bd':
                  row[value] = item['diferido']
                  break
                case 'bupa':
                  row[value] = null
                  break
              }
            }
          })
        }
        rows.push(row)
      })
    }
    return rows
  }


  return (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
            >
              Planes
            </StyledTableCell>
            {
              comparativePlan.map((plan, index) => (
                makeColumnsFromHeader(plan, index)
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              { comparativePlan['0']!=undefined &&
                <StyledTableCell align="right">{
                  row['0'] != null ? row['0'] : null
                }</StyledTableCell>
              }
              { comparativePlan['1'] != undefined &&
                <StyledTableCell align="right">{
                  row['1'] != null ? row['1'] : null
                }</StyledTableCell>
              }
              { comparativePlan['2'] != undefined &&
                <StyledTableCell align="right">{
                  row['2'] != null ? row['2'] : null
                }</StyledTableCell>
              }
              { comparativePlan['3'] != undefined &&
                <StyledTableCell align="right">{
                  row['3'] != null ? row['3'] : null
                }</StyledTableCell>
              }
              { comparativePlan['4'] != undefined &&
                <StyledTableCell align="right">{
                  row['4'] != null ? row['4'] : null
                }</StyledTableCell>
              }
            </StyledTableRow>
          ))}

        </TableBody>
      </Table>
    </TableContainer>
  );
}


export const TableCoverages =({})=>{

  return (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow><StyledTableCell >
              Coberturas
              </StyledTableCell>
            <StyledTableCell align="right">
              Total
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell component="th" scope="row">
              Total
            </StyledTableCell>
            <StyledTableCell align="right">
              Total
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}