 // Import required AWS SDK clients and commands for Node.js
 import { PublishCommand } from "@aws-sdk/client-sns";
 import { snsClient } from "../config/snsClient";
 
 
 export const sendMessageSNS = async (data) => {
     // Set the parameters
     const bodySns ={
      data:JSON.stringify(data.body),
      beneficts: JSON.stringify(data.benefitsByInsurer)
     }
     var params = {
         Message: JSON.stringify(bodySns), // MESSAGE_TEXT
         TopicArn: data.arn, //TOPIC_ARN
     };
     try {
       console.log("params --> ", params);
       const data = await snsClient.send(new PublishCommand(params));
       console.log("Success.",  data);
       return data; // For unit tests.
     } catch (err) {
       console.log("Error", err.stack);
     }
 };