import { useState } from "react";
//import { useLocation } from "react-router-dom";
import { makeStyles, Box, Typography } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

// import ImgFooter from "../../assets/img/img1.png";

const useStyles = makeStyles((theme) => ({
	footer: {
		// position: "relative",
		backgroundColor: "#9f0844",
        marginTop: "30px",
		color: theme.palette.common.white,
		width: "100%",

		[theme.breakpoints.down(600)]: {
			padding: "10px 0 20px 0",

			"& .MuiTypography-body1": {
				fontSize: "0.9rem",
			},
		},
	},
	footer__icons: {
		color: theme.palette.common.white,
		marginRight: 5,

		"&:last-child": {
			marginRight: 0,
		},
	},
}));

export const Footer = () => {
	// const { pathname } = useLocation();

	const classes = useStyles();

	const [year] = useState(new Date().getUTCFullYear());

	return (
		<footer className={classes.footer}>
			<Box paddingY={4} paddingX={1} textAlign="center">
				<Typography>
					Copyright &copy; {year} Gilbert & Boloña. Todos los derechos
					reservados.
				</Typography>
				<br />
				<Typography>
					<strong>Guayaquil: </strong>Edif. Xima. Oficina 506, Km 1.5 Vía
					Samborondón
				</Typography>
				<Typography>
					<strong>Quito: </strong>Edif. Libertador. Oficina 1202, Shyris N33-134
					y Rep. del Salvador
				</Typography>
				<Box display="flex" justifyContent="center" marginTop={1}>
					<a
						href="https://www.facebook.com/gilbertybolona"
						target="_blank"
						rel="noopener noreferrer"
						className={classes.footer__icons}
					>
						<FacebookIcon />
					</a>
					<a
						href="https://www.instagram.com/gilbertybolona/?hl=es-la"
						target="_blank"
						rel="noopener noreferrer"
						className={classes.footer__icons}
					>
						<InstagramIcon />
					</a>
					<a
						href="https://www.linkedin.com/company/gilbert-bolona/"
						target="_blank"
						rel="noopener noreferrer"
						className={classes.footer__icons}
					>
						<LinkedInIcon />
					</a>
					<a
						href="https://www.youtube.com/channel/UCgQuOD-liCC8YGOIY-yDiSA"
						target="_blank"
						rel="noopener noreferrer"
						className={classes.footer__icons}
					>
						<YouTubeIcon />
					</a>
				</Box>
			</Box>
			{/* <Box position="absolute">
				<img src={ImgFooter} alt="footer logo" />
			</Box> */}
		</footer>
	);
};
