import { useContext, useEffect } from 'react';
import { makeStyles, Container, Grid, Toolbar, Typography } from '@material-ui/core';
import { dataProvider } from './../../context/data/dataProvider';
import { appProdiver } from './../../context/appProvider';
import { useFirstStep } from '../../hooks/useFirstStep';

import QuoteStepper from '../ui/QuoteStepper';
import { ButtonsStepper, ButtonStart } from '../ui/Buttons';
import SecondStep from './SecondStep';
import FinalStep from './FinalStep';
import Comparative from './Comparative'
import LoadingPage from './LoadingPage';
import {ButtonGroups, ComparativePanelButtons} from './ButtonGroups';

const useStyles = makeStyles(theme => ({
  quote: {
    height: '100%',

    '& .MuiContainer-maxWidthLg': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative'
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent'
    },
    overflow: 'scroll'
  },
  quote__button: {
    backgroundColor: ({ start }) => !start ? 'white' : 'white',
    marginBottom: ({ start }) => !start ? 80 : null,
    position: ({ start }) => start ? 'absolute' : null,
    bottom: ({ start }) => start ? '6px' : null,
    padding: ({ start }) => start ? '0 30px 0 0' : null,
    maxWidth: ({ start }) => start ? '96%' : null,
    '& .MuiGrid-root': {
      textAlign: 'center'
    }
  },
  quote__containergrid: {
    flex: '1 0',
    maxHeight: '80%',
    height: '100%',
    overflow: 'scroll',
    width: ({ activeStep }) => activeStep==2?"100vw":null,
    position: ({ activeStep }) => activeStep==2?"relative":null,
    left: ({ activeStep }) => activeStep==2?"calc(-50vw + 50%)":null

  },
  quote__containergridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'scroll'
  }
}))

const Quote = () => {

  const { 
    start, 
    activeStep, 
    activate, 
    handleChangeStart, 
    handleNext, 
    handleBack, 
    handleSetActivate
  } = useContext(appProdiver);

  const {
    loading, 
    error, 
    inputs,
    quote,
    somePlanWasDeleted,
    detail,
    localLoading,
    infoComplete,
    openQuoteModal,
    comparativePlan,
    comparativeTotals,
    comparativeInsurer,
    ticketCreated,
    getPersonalData,
    handleComparativeTableResult,
    handleMoveOn,
    handleChangeInput,
    handleOlderChildren,
    handleSelectPlan,
    handleChangePeriod,
    handleChangeQuote,
    handleQuotingWhenCompleteVideo,
    handleQuotingWhenCompleteForms,
    handleDeleteBeneficiaries,
    handleContinueCompleteForm,
    handleCreateTicketOdoo,
    validatePhoneNumber,
    validateEmail,
    handleChangeProvince,
    handleDeleteSelectedPlan
  } = useFirstStep(handleNext);

  const classes = useStyles({ start, activeStep });
  const { data } = useContext(dataProvider);


  const stepAction = () => {
    switch (activeStep){
      case 1:
        return handleQuotingWhenCompleteForms()
      default:
        return handleMoveOn()
    }
  }

  const showContent = () => {
    // if (!start) {
    //   return (
    //     <Grid container spacing={3}>
    //       <Grid item xs={12} sm={6} md={4} lg={4}>
    //         <BusinessCard 
    //           icon={AccessibilityIcon}
    //           text="ingresa desde cualquier lugar"
    //         />
    //       </Grid>
    //       <Grid item xs={12} sm={6} md={4} lg={4}>
    //         <BusinessCard 
    //           icon={LockIcon}
    //           text="protegemos todos tus datos"
    //         />
    //       </Grid>
    //       <Grid item xs={12} sm={6} md={4} lg={4}>
    //         <BusinessCard 
    //           icon={StarIcon}
    //           text="la mejor agencia"
    //         />
    //       </Grid>
    //     </Grid>
    //   )
    // }else {
      return (
        <Grid container spacing={3} className={classes.quote__containergrid}>
          <Grid item xs={12} md={12} sm={12} lg={12} className={classes.quote__containergridItem}>
            {getCurrentStep()}
          </Grid>
        </Grid>
      )
  }

  const showButtonStart = () => {
    if (!start) {
      return (
        <Grid container className={classes.quote__button}>
          <Grid item xs={12}>
            <ButtonStart 
              text="Empezar"
              handleClick={handleChangeStart}
            />
          </Grid>
        </Grid>
      )
    }
  }

  const showButtons = () => {
    if (activeStep>=1 && activeStep<=3) {
      return (
        <Grid container className={classes.quote__button}>
          <Grid item xs={12}>
            <ButtonsStepper
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={activeStep === 0 ? getPersonalData : stepAction}
            />
          </Grid>
        </Grid>
      )
    }
  }


  const getCurrentStep = () => {
    if (activeStep === 0) {
      return (
        <LoadingPage
          loading={loading}
          error={error}
          handleGetInfoCustomer={handleQuotingWhenCompleteVideo}
          localLoading={localLoading}
        />
      )
    }
    if (activeStep === 1) {
      return (
        <SecondStep 
          activate={activate}
          openQuoteModal={openQuoteModal}
          ticketCreated={ticketCreated}
          handleSetActivate={handleSetActivate}
          inputs={inputs}
          handleChangeState={handleChangeInput}
          handleChangeProvince={handleChangeProvince}
          handleOlderChildren={handleOlderChildren}
          loading = {loading}
          errorQuoting = {error}
          infoComplete={infoComplete}
          localLoading = {localLoading}
          userData = {data}
          validatePhoneNumber={validatePhoneNumber}
          validateEmail={validateEmail}
          handleContinueCompleteForm={handleContinueCompleteForm}
          handleCreateTicketOdoo={handleCreateTicketOdoo}
        />
      )
    }

    if (activeStep === 2) {
      return (
        <FinalStep
          PersonalQuote = { quote }
          cardDetail = { detail }
          handleChangePeriod = { handleChangePeriod }
          handleSelectPlan={ handleSelectPlan }
          loading = { loading }
          handleChangeQuote= {handleChangeQuote}
          localLoading={localLoading}
          error = {error}
          planes = {inputs.planes}
          somePlanWasDeleted={somePlanWasDeleted}
          handleDeleteSelectedPlan={handleDeleteSelectedPlan}
        />
      )
    }
    if (activeStep === 3) {
      return (
        <Comparative
          PersonalQuote = { quote }
          cardDetail = { detail }
          planes = {inputs.planes}
          comparativeTotals = {comparativeTotals}
          summarycomparativePlan = {comparativePlan}
          comparativeInsurer ={comparativeInsurer}
        />
      )
    }
  }

  useEffect(()=>{
    console.log("activeStep +> ", activeStep)
    if (activeStep === 3){
      handleComparativeTableResult()
    }
  }, [activeStep])

  return (
    <div className={classes.quote}>
      <Container maxWidth="lg">
          <Grid container justifyContent="center">
            <Grid item xs={12} >
              <QuoteStepper />
            </Grid>
          </Grid>
          <ButtonGroups 
              activeStep={activeStep} 
              activate={activate}
              handleSetActivate={handleSetActivate}
              handleDeleteBeneficiaries={handleDeleteBeneficiaries}
            />
          {/* {
              activeStep === 2 && (
                <ComparativePanelButtons
                  activeStep={activeStep}
                  handleNext={handleNext}
                />
              )
            } */}

            {/* {
              activeStep === 2 && (
              <Grid item>
              <Typography variant="body1" color="initial"> {
                  <>Selecciona los productos que deseas comparar</>
              }
              </Typography>
            </Grid>
              )
            } */}

          {/* { showButtonStart() } */}
          { showContent() }
          { showButtons() }
      </Container>
    </div>
  )
}

export default Quote
