import { createContext, useReducer } from 'react';

import { SET_DATA_INFORMATION } from './../../types';
import { initialState, dataReducer } from './dataReducer';

export const dataProvider = createContext();

const DataProvider = ({ children }) => {

  const [state, dispatch] = useReducer(dataReducer, initialState);

  const setData = (data) => {
    dispatch({
      type: SET_DATA_INFORMATION,
      payload: data
    })
  }

  return (
    <dataProvider.Provider value={{ 
      data: state.data,
      setData
    }}>
      {children}
    </dataProvider.Provider>
  )
}

export default DataProvider