import { makeStyles, Typography } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import {ProfileLottieMotion} from '../ui/LottieMotion'
const useStyles = makeStyles(theme => ({
  userHeader: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
  },
  userHeader__icon: {
    backgroundColor: '#f7f7f7',
    borderRadius: '50%',
    width: 'fit-content',
    padding: '25px 28px',
    marginBottom: '20px',
    boxShadow: '1px 2px 7px -2px rgb(0 0 0 / 90%)',

    '& svg': {
      fontSize: '3rem'
    }
  },
  userHeader__text: {
    textAlign: 'center',
    color: 'white'
  }
}))

const UserHeader = ({ holderName, email, phoneNumber }) => {

  const classes = useStyles();

  return (
    <div className={classes.userHeader}>
       { ProfileLottieMotion() }
      {/* <div className={classes.userHeader__icon}>
      </div> */}
      <div className={classes.userHeader__text}>
        <Typography variant="h6" color="initial">{holderName}</Typography>
        <Typography variant="subtitle1" color="initial">{email}</Typography>
        <Typography variant="subtitle1" color="initial">{phoneNumber}</Typography>
      </div>
    </div>
  )
}

export default UserHeader
