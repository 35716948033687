import { SET_DATA_INFORMATION } from './../../types';

export const initialState = {
  data: {
    holderName: '',
    holderAge: '',
    email: '',
    spouse: '',
    spouseAge: '',
    children: '',
    phoneNumber: '',
    customer_id: '',
    url:'',
    plan: {
      insurer: '',
      name: '',
      value: ''
    },
    planes: [],
    olderChildren: []
  }
}

export const dataReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case SET_DATA_INFORMATION:
      return {
        ...state,
        data: {
          ...state.data,
          ...actions.payload
        }
      }
  
    default:
      return state
  }
}