import { useContext, useEffect } from 'react';
import { makeStyles, Container, Grid, Button } from '@material-ui/core';
import { dataProvider } from './../../context/data/dataProvider';
import { useFirstStep } from '../../hooks/useFirstStep';
import UserHeader from '../ui/UserHeader';
import Summary from '../ui/Summary';
import { CloseUserInfoButton } from '../ui/Buttons'

const useStyles = makeStyles(theme => ({
  userInfo: {
    height: '100%',
    display: 'flex',

    '& .MuiContainer-root': {
      height: '100%'
    },
    '& .MuiGrid-root': {
      height: '100%',
      width: '100%',
      margin: 0
    }
  },
  userInfo__header: {
    height: 'auto !important'
  },
  userInfo__content: {
    height: '60% !important',
    paddingTop: '0 !important'
  }
}))

const UserInfo = ({
  activeStep,
  handleCloseUserInfo,
  handleNext
}) => {

  const classes = useStyles();

  const { data } = useContext(dataProvider);
  const { handleDeleteSelectedPlan } = useFirstStep()
  
  return (
    <div className={classes.userInfo}>
      <Container maxWidth="md">
        <Grid container spacing={3}>
          {/* { activeStep!=2 && ( */}
            <CloseUserInfoButton
              text={"Cerrar"}
              handleClick={()=>{handleCloseUserInfo()}}
              />
            {/* ) */}
          {/* } */}
          <Grid item xs={12} className={classes.userInfo__header}>
            <UserHeader 
              holderName={data.holderName !== '' & data.holderName!=null ? data.holderName : 'Nombre Completo'}
              email={(data.email !== '' && data.email!=null) ? data.email : 'Email'}
              phoneNumber={data.phoneNumber !== ''  && data.phoneNumber!=null? data.phoneNumber : 'Teléfono'}
            />
          </Grid>

          <Grid item xs={12} className={classes.userInfo__content}>
            {
              (data.holderName || data.holderAge) && (
                <Summary
                  holder = {data.holderName !==''? data.holderName:null}
                  holderAge = {data.holderAge !==''? data.holderAge:null}
                  cedula={data.identification !== '' ? data.identification : null}
                  phone={data.phoneNumber !== '' ? data.phoneNumber : null}
                  spouse={data.spouse !== '' ? data.spouse : null}
                  spouseAge={data.spouseAge !== '' ? data.spouseAge : null}
                  children={data.children !== '' ? data.children : null}
                  olderChildren={data.olderChildren.length>0 ? data.olderChildren : null}
                  planes={data.planes.length >0 ? data.planes : null}
                  activeStep={activeStep}
                  handleDeleteSelectedPlan={handleDeleteSelectedPlan}
                  handleNext={handleNext}
                />
              )
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default UserInfo
