import { makeStyles, Button, IconButton, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import BackspaceRoundedIcon from '@material-ui/icons/BackspaceRounded';
import CompareIcon from '@mui/icons-material/Compare';

const useStyles = makeStyles(theme => ({
  buttonStart: {
    backgroundColor: '#9f0844',
    width: '30%',
    borderRadius: 100,
    color: 'white',
    boxShadow: '1px 2px 11px -2px rgba(0,0,0,0.9)',
    padding: 8,
    fontFamily: 'inherit',

    '&:hover': {
      backgroundColor: '#7c0837',
    }
  },
  buttonsStepper: {
    display: 'flex',
    justifyContent: 'space-between',
    // width: '95%'
    fontFamily: 'inherit',
  },
  buttonStepper__back: {
    color: 'black',
    fontFamily: 'inherit',
    '& .MuiButton-label': {
      color: 'black'
    },
    '&:hover': {
      backgroundColor: 'white !important'
    }
  },
  buttonStepper__next: {
    backgroundColor: '#7c0837',
    color: 'white',
    width: '100px',
    fontFamily: 'inherit',

    '&:hover': {
      backgroundColor:'#5f0529',
    }
  },
  buttonPrimary: {
    padding: '10px 28px',
    backgroundColor: ({ status }) => status ? '#7c0837' : 'transparent',
    color: ({ status }) => status ? 'white' : '#9f0844',
    marginRight: 10,
    border: '1px solid #9f0844',
    fontFamily: 'inherit',
    width: '100px',

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',

      '& svg': {
        fontSize: '2rem'
      },
      '& span': {
        fontSize: '14px'
      }
    },

    '&:hover': {
      backgroundColor: '#7c0837',
      color: 'white'
    },
    '&:first-child': {
      marginLeft: '0 !important'
    },
    '&:last-child': {
      margin: '0 !important'
    }
  },
  buttonSummary: {
    display: "contents",
    padding: '10px 28px',
    backgroundColor: "transparent",
    color: "white",
    marginRight: 10,
    border: '1px solid #9f0844',
    fontFamily: 'inherit',
    width: '100px',

    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',

      '& svg': {
        fontSize: '2rem'
      },
      '& span': {
        fontSize: '14px'
      }
    }
    },
  buttonDownload: {
    background: 'white',
    color: '#7c0837',
    textTransform: 'uppercase',
    padding: '5px 6px',
    borderRadius: '8px',
      '&:hover': {
        background: '#7c0837', 
        color: 'white'
      },
  },
  buttonTableView:{
    marginLeft: '15px',
    background: 'white',
    color: '#7c0837',
    textTransform: 'uppercase',
    padding: '5px 6px',
    borderRadius: '8px',
    display:'none',
      '&:hover': {
        background: '#7c0837', 
        color: 'white'
      },
  },
  buttonPayment: {
    padding: '0 5px',

    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  buttonPurchase: {
    backgroundColor: 'tranparent',
    //border: ({ disabled }) => disabled ? '1px solid white' : null,
    color: ({ disabled }) => disabled ? 'white' : '#7c0837', //'white'
    textTransform: 'uppercase',
    padding: '5px 6px'
  },

  bottonAddChildrenOlder: {
    color: 'white',
    width: "100px",
    fontFamily: "inherit",
    backgroundColor: "#7c0837",
    borderRadius: "5%",
    height: "30px",
    margin: "0px",
    '&:hover': {
      backgroundColor: '#7c0837'
    },

  },
  bottonDeleteChildren: {
    margin:"0px"
  },
  bottonDeletePlanes: {
    margin:"0px",
    color: "white",
  '& svg': {
    fontSize: 20
    }
  },  

  bottonPeriodPayment: {
    margin: 'auto !important',
    padding: '10px',
    width: '120px',
    backgroundColor: ({ status, addDisabled }) => !addDisabled? status? "#9f0844": "transparent" : status? "#a03044": "transparent",
    borderBottom: ({ status, addDisabled }) => !addDisabled? null : status? "double": null,
    color: ({ status, addDisabled }) => !addDisabled? status? "white": "#9f0844" : status? "white": "white",
    marginRight: 10,
   // border: '1px solid #9f0844',
    fontFamily: 'inherit',

    '& .MuiButton-label': {
      display: 'flex',

      '& svg': {
        fontSize: '1.2rem'
      },
      '& span': {
        fontSize: '10px'
      }
    },

    '&:hover': {
      backgroundColor: ({ addDisabled }) => !addDisabled? '#9f0844': null,
      borderBottom: ({ addDisabled }) => !addDisabled? null : "double",
      color: 'white'
    },
    '&:first-child': {
      marginLeft: '0 !important'
    },
    '&:last-child': {
      margin: '0 !important'
    }
  },
  bottonOpenInfoResumen: {
    padding: '5px',
    backgroundColor: ({ status }) => status ? '#9f0844' : 'transparent',
    color: ({ status }) => status ? 'white' : '#9f0844',
    marginRight: 8,
   // border: '1px solid #9f0844',
    fontFamily: 'inherit',

    '& .MuiButton-label': {
      display: 'flex',
      fontWeight: 700,
      '& svg': {
        fontSize: '0.9rem'
      },
      '& span': {
        fontSize: '10px'
      }
    },

    '&:hover': {
      backgroundColor: '#9f0844',
      color: 'white'
    },
    '&:first-child': {
      marginLeft: '0 !important'
    },
    '&:last-child': {
      margin: '0 !important'
    },
    [theme.breakpoints.down(700)]: {
      display: 'none',
    },
    [theme.breakpoints.down("md")]: {
      display: 'none',
    }
  },
  bottonCloseInfoResumen: {
    padding: '5px',
    backgroundColor: 'transparent',
    color:'white', 
  },

  modal__botton: {
    display: "block",
    margin: "5px",
    color: "white",
    backgroundColor: '#7c0837',
    '& .MuiTypography-root': {
      fontFamily: "inherit",
      [theme.breakpoints.down("xs")]: {
        fontSize: '0.9em',
      }
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#9f0844'
    },
  },
}))

export const ButtonStart = ({ text, handleClick }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.buttonStart}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

export const ButtonsStepper = ({ activeStep, handleBack, handleNext }) => {

  const classes = useStyles();

  return (
    <div className={classes.buttonsStepper}>
      <Button
        className={classes.buttonStepper__back}
        onClick={handleBack}
        disabled={activeStep === 1 ? true : false}
      >
        <ArrowBackIosIcon /> Regresar
      </Button>
      {
        activeStep === 1 && (
          <Button
            className={classes.buttonStepper__next}
            onClick={handleNext}
          >
            Siguiente
      </Button>
        )
      }
    </div>
  )
}

export const ButtonPrimary = ({ icon: Icon, title, status, value, handleClick }) => {

  const classes = useStyles({ status });

  return (
    <Button
      variant="contained"
      className={classes.buttonPrimary}
      onClick={() => handleClick(value)}
    >
      <Icon />
      <span>{title}</span>
    </Button>
  )
}

export const IconButtonPayment = ({ type, text, icon: Icon, handleClick }) => {
  const classes = useStyles({ type });

  return (
    <IconButton 
      aria-label={text}
      onClick={handleClick}
      className={classes.buttonPayment}
    >
      <Icon />
    </IconButton>
  )
}

export const ButtonPurchasePlan = ({ handleClick, disabled }) => {
  const classes = useStyles({ disabled });

  return (
    <Button
      //disabled={disabled}
      className={classes.buttonPurchase}
      onClick={handleClick}
    >
      {disabled?'retirar':'agregar'}
    </Button>
  )
}

export const AddButtonChildren = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.bottonAddChildrenOlder}
      onClick={handleClick}
    >
      <AddCircleRoundedIcon/>
      <Typography>
        Agregar
      </Typography>
    </IconButton>
  )
}

export const DeleteButtonChildren = ({ handleClick, jump }) => {
  const classes = useStyles();
  return (
    <IconButton 
      aria-label="delete"
      onClick={() => handleClick(jump)}
      className={classes.bottonDeleteChildren}
      >
      <BackspaceRoundedIcon />
    </IconButton>

  )
}

export const DeleteButtonPlanes = ({ handleClick, jump, planes }) => {
  const classes = useStyles();
  return (
    <IconButton 
      aria-label="delete"
      onClick={() => handleClick(planes, jump)}
      className={classes.bottonDeletePlanes}
      >
      <BackspaceRoundedIcon />
    </IconButton>

  )
}

export const PeriodButton = ({
        status, 
        period, 
        index, 
        handleSetActive, 
        indexes, 
        prod, 
        insurer, 
        handleChangePeriod, 
        PersonalQuote, 
        handleChangeTotalButtons,
        handleQuotingAgain,
        addDisabled
        
    }) => {
  const classes = useStyles({ status, addDisabled });

  const toggleClass = async () => {
    try{
      var result = {}
      for (let ind in indexes){
        if (ind==index){
          result[ind] = true
        }
        else result[ind] = false
      }
      handleSetActive(result)
      if (handleQuotingAgain(index)){
        console.log(period)
        const quote = await handleChangePeriod(PersonalQuote, period[0], insurer, prod)
        handleChangeTotalButtons(quote, index)
      }
    }
    catch (error) {
      throw error
    }
  }

  return (
    <Button 
      id={index}
      className={classes.bottonPeriodPayment}
      onClick={toggleClass}
      key={index}
    >
        {period[1]}
    </Button>
  )
}

export const OpenUserInfoButton = ({text, handleClick}) => {
  const classes = useStyles({});
  return (
    <Button 
      className={classes.bottonOpenInfoResumen}
      onClick = {handleClick}
    >
        {text}
    </Button>
  )
}

export const CloseUserInfoButton = ({ text, handleClick }) => {
  const classes = useStyles();
  return (
    <Button 
      className={classes.bottonCloseInfoResumen}
      onClick = {handleClick}
    >
        {text}
    </Button>
  )
}

export const CompleteFormManually = ({ handleContinueCompleteForm }) => {
  const classes = useStyles()
  return (
    <Button className={classes.modal__botton}
      onClick={handleContinueCompleteForm}>
      <Typography>
        Completar formulario manualmente
      </Typography>
  </Button>
  )                       
}

export const CreateTicketOdoo = ({handleCreateTicketOdoo}) => {
  const classes = useStyles()
  return (
    <Button className={classes.modal__botton}
      onClick={handleCreateTicketOdoo}>
      <Typography>
        Pedir a un ejecutivo que se contacte contigo
      </Typography>
    </Button>
  )
}

export const OpenComparativePanel = ({buttonAction}) => {
  const classes = useStyles()
  return (
    <Button 
      variant="contained"
      onClick={() => buttonAction()}
      className={classes.buttonPrimary}>
        <CompareIcon/>
      <span>
        Comparar Planes
      </span>
    </Button>
  )
}

export const OpenComparativeSummary = ({buttonAction}) => {
  const classes = useStyles()
  return (
    <Button 
      variant="contained"
      onClick={() => buttonAction()}
      className={classes.buttonDownload}>
      <span>
          Comparar Tabla
      </span>
    </Button>
  )
}

export const ButtonDownloadReport = ({ handleClick,  pdfUrl, text}) => {
    const classes = useStyles();
  return (
    <Button
       className={classes.buttonDownload}
       onClick={handleClick}
       href= {pdfUrl} 
       target='_blank'
       rel="noopener noreferrer" 
       download= {pdfUrl}
     >{text}</Button>
    )
}

export const ButtonTableViewReport = ({ handleClick}) => {
  const classes = useStyles();
return (
  <Button
     className={classes.buttonTableView}
     onClick={handleClick}
   >Ver Tabla Comparativa</Button>
  )
}

