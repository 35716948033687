import { useContext } from 'react';
import { makeStyles, withStyles, Stepper, Step, StepLabel, StepConnector, Hidden } from '@material-ui/core';
import clsx from 'clsx';

import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import PaymentIcon from '@material-ui/icons/Payment';

import { appProdiver } from './../../context/appProvider';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'inherit'
  },
  active: {
    backgroundColor: '#9f0844',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor: '#9f0844',
  },
});
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: '#9f0844',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: '#9f0844',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <HomeIcon />,
    2: <PersonIcon />,
    3: <PaymentIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function getSteps() {
  return ['Conociéndonos', 'Tus beneficiarios', 'Tu plan ideal'];
}

const QuoteStepper = () => {

  const { activeStep } = useContext(appProdiver);

  const steps = getSteps();

  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>
            <Hidden only={['xs', 'sm']}>
              {label}
            </Hidden>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default QuoteStepper
