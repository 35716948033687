import { useState, useContext, useEffect } from 'react'
import { appProdiver } from './../context/appProvider';
import { dataProvider } from './../context/data/dataProvider';
import { awsQuoter, awsCustomerInfo, awsUpdateCustomerInfo } from '../third_party_api/aws'
import { odooCreateTicket, odooCreateLead } from '../third_party_api/odoo'
import { poll } from './polly';
export const useFirstStep = (handleNext) => {
  const POLL_INTERVAL = 5000;
  const POLL_ATTEMPTS = 8
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [quote, setQuote] = useState({});
  const [detail, setDetail] = useState({});
  const [localLoading, setLocalLoading] = useState(true)
  const [infoComplete, setInfoComplete] = useState(false)
  const [openQuoteModal, setQuoteModal] = useState(false)
  const [ticketCreated, setTicketCreated] = useState(false)
  const [somePlanWasDeleted, setSomePlanWasDeleted] = useState(false)
  const [comparativePlan, setComparativePlan] = useState([])
  const [comparativeInsurer, setComparativeInsurer] = useState([])
  const [comparativeTotals, setComparativeTotals] = useState([])

  const [inputs, setInputs] = useState({
    holderName: '',
    holderAge: '',
    spouse: '',
    spouseAge: '',
    children: '',
    region: '',
    married: null,
    has_children: null,
    contact_id: '',
    olderChildren: [],
    planes: [],
    created_lead: null,
    plan: {
      name: '',
      insurer: '',
      value: ''
    }
  })

  const { activate, handleSetActivate, handleHiddenUserInfo, handleChangeStart } = useContext(appProdiver)
  const { data, setData } = useContext(dataProvider);


  const getPersonalData = async () => {
    setLoading(true)

    try {
      setTimeout(() => {
        setLoading(false)

        // TODO: Send lead to Odoo Core

        handleNext()
      }, 2500);
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  const handleOlderChildren = (olderChildren) => {
    setInputs({
      ...inputs,
      olderChildren
    })
    setData({
      ...data,
      olderChildren
    })
  }

  const handleMoveOn = () => {
    setData(inputs)
    handleNext();
  }

  const handleChangePageWhenQuoting = (resp, step = 1) => {
    setDetail(resp)
    setLocalLoading(false)
    setTimeout(() => {
      handleHiddenUserInfo(true)
      setLoading(false)
      handleChangeStart()
      handleNext(step)
    }, 1000)
  }

  const handleSetLoading = (estado) => {
    setLoading(estado)
  }

  const handleDetailCard = (baseQuote) => {
    var result = {}
    var preferencialAmount = {}
    var products = Object.keys(baseQuote)
    var valuesProducts = Object.values(baseQuote)
    for (let x = 0; x < products.length; x++) {
      var _det = []
      var national = []
      var international = []
      var sdet = Object.keys(valuesProducts[x])
      preferencialAmount[products[x]] = Object.values(Object.values(valuesProducts[x])[0])
      for (let y = 0; y < sdet.length; y++) {
        var dets = sdet[y].split('-')
        if (dets[0] != undefined) {
          const value = {
            "value": y * 25,
            "realValue": parseInt(dets[0])
          }
          national.push(value)
        }
        if (dets[1] != undefined) {
          const value = {
            "value": y * 25,
            "realValue": parseInt(dets[1])
          }
          international.push(value)
        }
      }
      _det.push({
        national,
        international
      }
      )
      result[products[x]] = _det
    }
    return { result, preferencialAmount }
  }

  const handleTotalDetailCard = (insurer, baseQuote) => {
    var result = {}
    var amount = {}
    const detailCard = handleDetailCard(baseQuote)
    const individualDetails = detailCard['result']
    const _preferencialAmount = detailCard['preferencialAmount']
    result[insurer] = individualDetails
    amount[insurer] = _preferencialAmount
    return { result, amount }
  }

  const handleSetInfoComplete = (status) => {
    setInfoComplete(status)
  }

  const handleUpdateContactInfo = (inputs, data, odoo = false) => {
    let item = {
      contact_age: inputs['holderAge'],
      contact_name: inputs['holderName'],
      status: 'complete',
      region: inputs['region'] != '' ? inputs['region'] : '',
      spouse_name: inputs["married"] ? inputs['spouse'] : null,
      spouse_age: inputs["married"] ? inputs['spouseAge'] : null,
      contact_children: inputs["has_children"] ? inputs['children'] : null,
      contact_older_children: (inputs["has_children"] && inputs['olderChildren'].length > 0) ? inputs['olderChildren'] : null,
      contact_email: data.email != '' ? data.email : null,
      contact_phone_number: data.phoneNumber != '' ? data.phoneNumber : null,
      created_lead: odoo
    }
    let result = awsUpdateCustomerInfo(inputs['contact_id'], item)
    return result
  }

  const handleCreateTicket = (inputs, data) => {
    let item = {
      x_studio_edad: inputs['holderAge'],
      name: inputs['holderName'],
      x_studio_conyugue: inputs["married"] ? inputs['spouse'] : "",
      x_studio_edad_del_conyugue_1: inputs["married"] ? inputs['spouseAge'] : "",
      x_studio_cantidad_de_hijos_1: inputs["has_children"] ? parseInt(inputs['children']) : "",
      x_studio_hijos_mayores_de_24_aos: (inputs["has_children"] && inputs['olderChildren'].length > 0) ? inputs['olderChildren'] : "",
      email_from: data.email != '' ? data.email : "",
      phone: data.phoneNumber != '' ? data.phoneNumber : "",
      website: data.url != '' ? data.url : '',
      x_studio_provincia: inputs['region'] != '' ? inputs['region'] : '',
      source_id: '35',
      priority: '3',
      medium_id: '14'
    }
    let result = odooCreateLead(item)
    return result
    // return item
  }


  const handleQuoteOn = async (step, time, result = null) => {
    try {
      setTimeout(async () => {
        var quote = null
        if (!result) {
          setData(inputs)
          quote = await awsQuoter(inputs)
          console.log("Quote result ", quote)
          if (!inputs['created_lead']) {
            handleCreateTicket(inputs, data).then(resp => {
              // if ('message' in resp['data']) handleUpdateContactInfo(inputs, data, true)
              if (resp.item) handleUpdateContactInfo(inputs, data, true)

              else handleUpdateContactInfo(inputs, data)
            })
          }
        }
        else {
          quote = await awsQuoter(result)
        }
        setQuote(quote)
        const mapping = Object.keys(quote['result']).map(insurer => {
          return handleTotalDetailCard(insurer, quote['result'][insurer])
        })
        Promise.all(mapping).then(res => {
          return res
        }).then(response => {
          handleChangePageWhenQuoting(response, step)
        }).
          catch(error => {
            throw error
          })
      }, time);
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }

  const handleChangePeriod = async (PersonalQuote, period, insurer, product) => {
    setLoading(true)
    setLocalLoading(true)
    try {
      var _quote = await awsQuoter(inputs, { "period": period, "insurer": insurer, "product": product })
      setLocalLoading(false)
      PersonalQuote['result'][insurer][product] = _quote['result']
      setTimeout(() => {
        setLoading(false)
      }, 750)
      return PersonalQuote
    } catch (error) {
      setError(error)
      setLoading(false)
      return null
    }
  }

  const handleCustomerInfoInputs = (customerInfo) => {
    var result = {}
    result['holderName'] = customerInfo['contact_name'] != undefined ? customerInfo['contact_name'] : ''
    result['holderAge'] = customerInfo['contact_age'] != undefined ? customerInfo['contact_age'] : ''
    result['region'] = customerInfo['region'] != undefined ? customerInfo['region'] : ''
    result['married'] = customerInfo['married'] != undefined ? customerInfo['married'] : null
    result['has_children'] = customerInfo['has_children'] != undefined ? customerInfo['has_children'] : null
    result['contact_id'] = customerInfo['contact_id'] != undefined ? customerInfo['contact_id'] : ''
    result['spouse'] = customerInfo['spouse_name'] != undefined ? customerInfo['spouse_name'] : ''
    result['spouseAge'] = customerInfo['spouse_age'] != undefined ? customerInfo['spouse_age'] : ''
    result['children'] = customerInfo['contact_children'] != undefined ? customerInfo['contact_children'] : ''
    result['olderChildren'] = customerInfo['contact_older_children'] != undefined ? customerInfo['contact_older_children'] : []
    result['created_lead'] = customerInfo['created_lead'] != undefined ? customerInfo['created_lead'] : null
    setInputs({
      ...inputs, ...result
    })
    return result
  }

  const handleCustomerInfoData = (customerInfo, url = null) => {
    var result = {}
    result['holderName'] = customerInfo['contact_name'] != undefined ? customerInfo['contact_name'] : ''
    result['holderAge'] = customerInfo['contact_age'] != undefined ? customerInfo['contact_age'] : ''
    result['email'] = customerInfo['contact_email'] != undefined ? customerInfo['contact_email'] : ''
    result['phoneNumber'] = customerInfo['contact_phone_number'] != undefined ? customerInfo['contact_phone_number'] : ''
    result['contact_id'] = customerInfo['contact_id'] != undefined ? customerInfo['contact_id'] : ''
    result['children'] = customerInfo['contact_children'] != undefined ? customerInfo['contact_children'] : ''
    result['olderChildren'] = customerInfo['contact_older_children'] != undefined ? customerInfo['contact_older_children'] : []
    result['url'] = url
    if (customerInfo['status'] === 'complete') setInfoComplete(true)
    setData({
      ...data, ...result
    })
    return result
  }

  const handleSpouseChildrenSection = (customerInfo) => {
    if (customerInfo['married']) handleSetActivate({ marriedActivated: !activate.marriedActivated })
    if (customerInfo['has_children']) handleSetActivate({ soonsActivated: !activate.soonsActivated })
  }

  const handleQuotingWhenCompleteForms = async () => {
    setLoading(true)
    setLocalLoading(true)
    await handleQuoteOn(1, 2500)
  }

  const handleContinueCompleteForm = () => {
    if (validateEmail(data.email) || validatePhoneNumber(data.phoneNumber)) {
      handleChangeStart()
      setInfoComplete(false)
      setQuoteModal(false)
    }
  }

  const handleCreateTicketOdoo = () => {
    let item = {
      email: data.email != '' ? data.email : "",
      phone: data.phoneNumber != '' ? data.phoneNumber : "",
      source_id: 'health_quoter',
      source: 'Cotizador de Salud',
    }
    odooCreateTicket(item).then(
      resp => {
        if ('message' in resp['data']) {
          awsUpdateCustomerInfo(inputs['contact_id'], {
            contact_email: data.email != '' ? data.email : "",
            contact_phone_number: data.phoneNumber != '' ? data.phoneNumber : "",
          }).then(resp =>
            setTicketCreated(true))
        }

      }
    )
  }

  const handleQuotingWhenCompleteVideo = async () => {
    try {
      const contact_id = window.location.search.split("=")[1]
      var customerInfo = await poll({
        fn: awsCustomerInfo,
        arg: contact_id,
        interval: POLL_INTERVAL,
        maxAttempts: POLL_ATTEMPTS,
      })
      console.log('customer info: ', customerInfo)
      if ('error' in customerInfo) {
        setError(customerInfo['error'])
        setLoading(false)
        return
      }
      const inputs = handleCustomerInfoInputs(customerInfo)
      const data = handleCustomerInfoData(customerInfo, window.location.href)

      if (customerInfo['status'] === 'complete') {
        if (inputs['created_lead']) {
          handleCreateTicket(inputs, data).then(resp => {
            if ('message' in resp['data']) awsUpdateCustomerInfo(contact_id, {
              created_lead: true
            })
          })
        }
        if(customerInfo['married'] || customerInfo['has_children']){
          handleSpouseChildrenSection(customerInfo)
          setQuoteModal(true)
          setLocalLoading(false)
          setLoading(false)
          handleNext()
        }
        else await handleQuoteOn(2, 2500, inputs)
      }
      // else if (customerInfo['status'] === 'pending') {
        else if (customerInfo['status'] === 'request_info') {
        handleSpouseChildrenSection(customerInfo)
        setLocalLoading(false)
        setLoading(false)
        handleNext()
      }
      else {
        handleSpouseChildrenSection(customerInfo)
        setQuoteModal(true)
        setLocalLoading(false)
        setLoading(false)
        handleNext()
      }
    } catch (error) {
      setError(error)
      return null
    }
  }

  const handleChangeInput = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value
    })
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeProvince = (region) => {
    setInputs({
      ...inputs,
      region
    })
  }

  const handleCompleteResumen = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeQuote = (quote) => {
    setQuote(quote)
  }

  const handleSelectPlan = (name, insurer, value, deductible) => {
    let planes = data.planes
    //const deductibleStr = deductible.toString()
    planes.push({ name, insurer, value , deductible})
    setData({
      ...data,
      planes
    })
    setInputs({
      ...inputs,
      planes
    })
  }

  const handleDeleteSelectedPlan = (_planes, planDeleted) => {
    console.log(planDeleted)
    console.log(_planes)
    const planes = _planes.filter((plan, i) => {
      if (plan.insurer !== planDeleted.insurer_name) return true
      else {
        if (plan.name !== planDeleted.prod_name) return true
        return false
      }
    })
    if (_planes.length != planes.length) {
      setSomePlanWasDeleted(true)
    }
    setData({
      ...data,
      planes
    })
    setInputs({
      ...inputs,
      planes
    })
  }

  const validateEmail = (email) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email)) return true
    return false;
  }
  const validatePhoneNumber = (phoneNumber) => {
    let reg = /^\+?\d{1,9}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
    if (reg.test(phoneNumber) && ((phoneNumber.length == 10 && phoneNumber[0] != "+") || (phoneNumber[0] == "+" && phoneNumber.length == 13))) return true
    return false;
  }

  const handleError = (error) => {
    setError(error)
  }

  const handleDeleteBeneficiaries = (e) => {
    setInputs({
      ...inputs,
      ...e
    })
    setData({
      ...data,
      ...e
    })
  }

  const handleGetPlanOptionNames = () => {
    const info = quote["info"];
    const insurer = [];
    const plan = [];
    for (const [key, value] of Object.entries(info)) {
      const data = value;
      const cinsurer = key;
      for (const [key, value] of Object.entries(data)) {
        if (!insurer.includes(value.insurer)) insurer.push(value.insurer);
        plan.push({
          plan: value.name,
          insurer: value.insurer,
          code: key,
          icode: cinsurer,
        });
      }
    }
    return { insurer, plan };
  };


  function handleChangePlanByInsurer(selectedInsurer) {
    const plans = [];
    const completeDetail = [];
    const resp = handleGetPlanOptionNames()
    if (selectedInsurer === "all" || selectedInsurer === null) {
      console.log("selectedInsurer > ", selectedInsurer);
      resp.plan.map((v, k) => {
        plans.push(v.plan);
        completeDetail.push(v);
      });
    } else {
      resp.plan.map((v, k) => {
        if (v.insurer === selectedInsurer) {
          plans.push(v.plan);
          completeDetail.push(v);
        }
      });
    }
    return { plans, completeDetail };
  }


  const handleComparativeTableResult = () => {
    inputs.planes.map((plan, ind)=>{
      const data = handleChangePlanByInsurer(plan.insurer)
      const index = data.plans.indexOf(plan.name);
      const completeDetail = data.completeDetail[index]
      const quoter = quote['result'][completeDetail.icode][completeDetail.code]
      quoter['info'] = {
          icode: completeDetail.icode, 
          code: completeDetail.code
      }
      if (!comparativePlan.includes(plan.name)){
        setComparativePlan(comparativePlan => [...comparativePlan, plan.name])
        setComparativeTotals(comparativeTotals => [...comparativeTotals, quoter[plan.deductible]])
        setComparativeInsurer(comparativeInsurer => [...comparativeInsurer, quoter])
  
      }
    })
  }


  return {
    loading,
    error,
    inputs,
    quote,
    detail,
    localLoading,
    infoComplete,
    openQuoteModal,
    somePlanWasDeleted,
    ticketCreated,
    comparativePlan,
    comparativeTotals,
    comparativeInsurer,
    handleComparativeTableResult,
    getPersonalData,
    handleMoveOn,
    handleChangeInput,
    handleSelectPlan,
    handleCompleteResumen,
    handleOlderChildren,
    handleQuoteOn,
    handleChangePeriod,
    handleChangeQuote,
    handleQuotingWhenCompleteVideo,
    handleError,
    handleSetLoading,
    handleDeleteBeneficiaries,
    handleSetInfoComplete,
    handleQuotingWhenCompleteForms,
    handleContinueCompleteForm,
    handleCreateTicketOdoo,
    validateEmail,
    validatePhoneNumber,
    handleDeleteSelectedPlan,
    handleChangeProvince
  }
}