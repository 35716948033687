import React from 'react'
import * as pre_loading from "../../assets/lottie-motion/pre_loading.json"
import * as success from "../../assets/lottie-motion/sucess.json"
import * as error_gb from '../../assets/lottie-motion/error_gb.json'
import * as warning_gb from '../../assets/lottie-motion/warning_gb.json'
import * as family_profile from '../../assets/lottie-motion/family_profile.json'

import { useLottie } from "lottie-react";
  
  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions3 = {
    loop: true,
    autoplay: true,
    animationData: error_gb.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: pre_loading.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions4 = {
    loop: true,
    autoplay: true,
    animationData: warning_gb.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const defaultOptions5 = {
    loop: true,
    autoplay: true,
    animationData: family_profile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

export const QuotingLottieMotion = (error, localLoading) => {
    const handleViewLottie = () => {
      if (error){
        return defaultOptions3
      }
      else {
        if (localLoading){
          return defaultOptions1
        }
        else {
          return defaultOptions2
        }
      }
    }
    const { View } = useLottie(handleViewLottie(), {
        height: 400,
        width:400, 
        margin: error==null? "100px auto": "40px auto",
    })
  return View
}


export const VideoWarningLottieMotion = () => {
  const { View } = useLottie(defaultOptions4, {
    height: 200,
    width:200, 
    margin: "auto",
  })
  return View
}

export const ProfileLottieMotion = () => {
  const { View } = useLottie(defaultOptions5, {
    height: 200,
    width:200, 
    margin: "-20px",
  })
  return View
}