import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { provinces } from '../../utils/provinces'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  comboSectionProduct: {
    marginLeft: '.5em',
    marginTop: '.5em',
    width: '170px !important',

  },
  comboSectionInsurer: {
    marginLeft: '.5em',
    width: '170px !important'
  },
  comboSectionDeductibles: {
    marginLeft: '.5em',
    marginTop: '.5em',
    width: '170px !important'
  }

}))

export const ComboBoxProvincias = ({
  handleChangeProvince
  }
) => {
  function handleInputChange(event, value) {
    handleChangeProvince(value.label);
  }

  return (
    <Autocomplete
      options={provinces}
      sx={{ width: 300 }}
      id="region"
      name="region"
      onChange={handleInputChange}
      renderInput={(params) => 
      <TextField {...params} 
      label="Provincia" 
      />}
    />
  );
}

export const ComboBoxProductos = ({ selectedPlanAction, planNames, namePlans, handleInputSelectPlan }) => {
  const classes = useStyles({ });

  function handleInputChange(event, value) {
    selectedPlanAction(value);
  }

  React.useEffect (()=>{
    if(namePlans !== ""){
      console.log("namePlans use effect => ", namePlans);
      handleInputSelectPlan(namePlans)
      // handleInputChange(namePlans)
    }
  },[])


  return (
    <Autocomplete
      className={classes.comboSectionProduct}
      sx={{ width: 300 }}
      options={planNames}
      id="plan"
      name="plan"
      size='small'
      onChange={handleInputChange}
      defaultValue={namePlans}
      renderInput={(params) => 
        <TextField {...params} 
        label="Planes" 
        />}
    />
  )
}

export const ComboBoxInsurers = ({ selectedInsurerAction, insurerNames , nameInsurer}) => { 
  const classes = useStyles({ });

  function handleInputChange(event, value) {
      selectedInsurerAction(value);   
  }

  React.useEffect(()=>{
    if (nameInsurer !==""){
      selectedInsurerAction(nameInsurer)
    }
  }, [])

  return (
    <Autocomplete
      className={classes.comboSectionInsurer}
      sx={{ width: 200 }}
      options={insurerNames}
      id="insurer"
      name="insurer"
      size='small'
      onChange={handleInputChange}
      // value={nameInsurer}
      defaultValue={nameInsurer}
      renderInput={(params) => 
        <TextField {...params} 
        label="Aseguradoras" 
        />}
    />
  )
}


export const ComboBoxDeductibles = ({ selectedDeductiblesAction, deductibleNames, isDissabled, handleInputSelectDeductibles, nameDeductible }) => {
  const classes = useStyles({ });
  console.log("disabled => ", isDissabled)
  function handleInputChange(event, value) {
    selectedDeductiblesAction(value);
  }

  React.useEffect(()=>{
    console.log("nameDeductible select => ", nameDeductible)
    if (nameDeductible !== undefined){
      handleInputSelectDeductibles(nameDeductible)
    }
  }, [deductibleNames])

  return (
    <Autocomplete
      className={classes.comboSectionDeductibles}
      sx={{ width: 300 }}
      disabled={isDissabled}
      options={deductibleNames}
      id="deductibles"
      name="deductibles"
      size='small'
      onChange={handleInputChange}
      //value={deductible}
      defaultValue={nameDeductible}
      renderInput={(params) => 
        <TextField {...params} 
        label="Deducibles" 
        />}
    />
  )
}


