import { createContext, useReducer } from 'react';

import {
  SET_START,
  SET_STEPPER,
  SET_ACTIVE,
  SET_HIDDEN
} from './../types';
import { initialState, appReducer } from './appReducer';

export const appProdiver = createContext();

const AppProvider = ({ children }) => {

  const [state, dispatch] = useReducer(appReducer, initialState);

  const handleChangeStart = () => {
    dispatch({
      type: SET_START,
    })
    dispatch({
      type: SET_HIDDEN,
      payload: true
    })
    dispatch({
      type: SET_STEPPER,
      payload: 1
    })
  }

  const handleHiddenUserInfo = (status) => {
    dispatch({
      type: SET_HIDDEN,
      payload: status
    })
  }

  const handleNext = (step=1) => {
    console.log("step ", step)
    if (state.activeStep < 3) {
      const payload = state.activeStep + step
      dispatch({
        type: SET_STEPPER,
        payload
      })
      if (state.activeStep == 2){
        dispatch({
          type: SET_HIDDEN,
          payload: false
        })
      }
    }
  };

  const handleBack = () => {
    if (state.activeStep > 0) {
      const payload = state.activeStep - 1
      dispatch({
        type: SET_STEPPER,
        payload
      })
    }
  };

  const handleSetActivate = (activateNow) => {
    dispatch({
      type: SET_ACTIVE,
      payload: { ...activateNow }
    })
  }

  return (
    <appProdiver.Provider value={{
      start: state.start,
      activeStep: state.activeStep,
      activate: state.activate,
      hidden: state.hidden,
      handleChangeStart,
      handleNext,
      handleBack,
      handleSetActivate,
      handleHiddenUserInfo
    }}>
    {children}
    </appProdiver.Provider>
  )
}

export default AppProvider