export const poll = async ({ fn, arg, interval, maxAttempts }) => {
    let attempts = 0;

    const executePoll = async (resolve, reject) => {
        const result = await fn(arg);
        console.log("Poll result: ", result)
        attempts++;
        if (maxAttempts && attempts == maxAttempts) {
            return resolve({
                error: 'Exceeded max attempts'
            })
        }
        if ("error" in result || result.status === "pending") {
            setTimeout(executePoll, interval, resolve, reject);
        } else {
            return resolve(result);
        }
    };
    return new Promise(executePoll);
};


