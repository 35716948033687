export const CardDetailTitulares = (periodo, insurer) => {

    switch(periodo) {
        case "anual": 
            if (insurer== 'bmi'){
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "Super de Bancos (3,5%)", "Valor Anual",
                    "Debito Mensual", "Diferido Especial", "Valor Contado"
                ] 
            }
            else if (insurer == 'bupa'){
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "Super de Bancos (3,5%)", "Valor Anual"
                ]
            }
            else if ( insurer == 'bd'){
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "Valor Anual Contado", "Valor Anual diferido preferencial"
                ]
            }
            else if ( insurer == 'all'){
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "Superintendencia de Bancos (3,5%)", "Total", "Valor Contado", "Valor Financiado", "Diferido Especial"
                ]
            }
        case 'semestry':
            if (insurer == 'bupa'){
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "Super de Bancos (3,5%)", "1. Pago Semestral", "2. Pago Semestral"
                ]
            }
            else if (insurer == 'bd') {
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "1. Pago Semestral", "2. Pago Semestral",
                    "1. Pago Semestral Diferido", "2. Pago Semestral Diferido"
                ]
            }
        case 'trimestry':
            if (insurer == 'bupa'){
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "Super de Bancos (3,5%)", "1. Pago Trimestral", "Pagos Consecuentes Trimestral"
                ]
            }
            else if (insurer == 'bd') {
                return [
                    "Subtotal", "Seguro Campesino (0,5%)", "1. Pago Trimestral", "Pagos Consecuentes Trimestral",
                    "1. Pago Trimestral Diferido", "Pagos Consecuentes Trimestral Diferido"
                ]
            }   
        }
    }