import { useState } from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import HelpIcon from '@mui/icons-material/Help';
import { makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
const useStyles = makeStyles(theme => ({
  payment__sliders: {
    display: 'flex',
    marginTop: "auto",
    '& div': {
      maxWidth: '50%',
      width: '15rem',
      '& span': {
        color:  ({ addDisabled }) => addDisabled ? 'white' : '#7c0837',
        '& .css-1gv0vcd-MuiSlider-track': {
          backgroundColor: ({ addDisabled }) => addDisabled ? "transparent" : '#7c0837',
        }
      }
    },
    '& svg': {
      marginLeft: '12px',
      color:  ({ addDisabled }) => addDisabled ? 'white' : '#7c0837',
    },
      paddingBottom: '10px',
      justifyContent: 'center'
    },
  box__container: {
    display: 'flex',
    margin:"auto"
  },
  icon__sliders: {
    margin: "auto"
  }

}))

export const PaymentSliders = ({ deductibles, value, handleChange, scale, addDisabled}) => {
    const classes = useStyles({addDisabled});

    return (
        <div className={classes.payment__sliders}>
            <AddIcon  className={classes.icon__sliders} position="start"/>
            <Box className={classes.box__container}>
                <Slider
                    valueLabelDisplay="auto"
                    marks={deductibles['national']}
                    value={value}
                    min={deductibles['national'][0]['value']}
                    step={1}
                    max={deductibles['national'][deductibles['national'].length-1]['value']}
                    scale={scale}
                    onChange={handleChange}
                />
            </Box>
            <RemoveIcon className={classes.icon__sliders} position="end"/>
            {/* <Tooltip className={classes.icon__sliders}  title={"Selecciona el deducible que mayor se ajuste a tus comodidades"}>
              <HelpIcon  position="end"/>
			      </Tooltip> */}
        </div>
    )
  }
