import React from 'react';

import AppProvider from './../context/appProvider';
import DataProvider from './../context/data/dataProvider';
import Content from './containers/Content';

function App() {
  return (
    <AppProvider>
      <DataProvider>
        <Content />
      </DataProvider>
    </AppProvider>
  );
}

export default App;
