import React from "react";
import { Container, Grid } from "@material-ui/core";
import { TitleMain } from "../ui/Title"
import { CardWithIcon } from "../ui/Cards";
import { mookCards } from '../../utils/mookCards';


export const InfoContainer = () => {
	return (
		<Container className="mainContainer" maxWidth="lg">
			<TitleMain color text="Nuestros servicios" grid />
			<Grid container spacing={2}>
				{mookCards.map((card, index) => (
					<Grid key={index} item xs={12} sm={6} md={4}>
						<CardWithIcon
							img={card.img}
							icon={card.icon}
							title={card.title}
							text={card.text}
							url={card.url}
						/>
					</Grid>
				))}
			</Grid>
		</Container>
	);
};