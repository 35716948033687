const axios = require("axios")

const clientAxios = axios.create({
	headers: {
		"Content-Type": "application/json;charset=UTF-8",
		// "Access-Control-Allow-Origin": "*"
	},
});

module.exports = {
    clientAxios
}

// {
// 	//baseURL: process.env.DEV_UPLAYER_URL,
// 	headers: {
// 		"Content-Type": "application/json;charset=UTF-8",
// 		"Access-Control-Allow-Origin": "*"
// 	},
// }